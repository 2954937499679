import { Code, ConnectError } from '@bufbuild/connect-web'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, FormControl, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { AccountService } from 'gen/proto/account/account_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  account: Account
  handleClose: () => void
}

export const UpdateEmailDialog: FC<Props> = ({ open, account, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const accountService = useGrpcClient(AccountService)

  const segmentUpdateInput = z
    .object({
      email: z
        .string()
        .min(1, { message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMin') })
        .email(t('features.settings.myAccount.updateEmailDialog.messageValidationEmailIsInvalid')),
      confirmEmail: z.string().min(1, { message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMin') }),
    })
    .refine((data) => data.email === data.confirmEmail, {
      message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMatch'),
      path: ['confirmEmail'],
    })

  type InputSchema = z.infer<typeof segmentUpdateInput>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(segmentUpdateInput),
    defaultValues: {
      email: '',
      confirmEmail: '',
    },
  })

  const updateEmail = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await accountService.updateEmail({ currentEmail: account.email, newEmail: input.email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.myAccount.updateEmailDialog.messageSaved'), { severity: 'success' })
      handleClose()
      window.location.reload() // Reload the page to ensure the user is directed to the login page
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.settings.myAccount.updateEmailDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.settings.myAccount.updateEmailDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='4px'>
          {t('features.settings.myAccount.updateEmailDialog.title')}
        </Typography>
        <Typography fontSize='13px' color='text.secondary' marginBottom='16px'>
          {t('features.settings.myAccount.updateEmailDialog.description', { email: account.email })}
        </Typography>

        <Box marginBottom='20px'>
          <Controller
            control={control}
            name='email'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '100%', marginBottom: '12px' }}>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.settings.myAccount.updateEmailDialog.fieldEmail')}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
          <Controller
            control={control}
            name='confirmEmail'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.settings.myAccount.updateEmailDialog.fieldConfirmEmail')}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.settings.myAccount.updateEmailDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(updateEmail)} variant='contained'>
            {t('features.settings.myAccount.updateEmailDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
