import { Box, Button, Dialog, Typography } from '@mui/material'
import { env } from 'config/env'
import { useAccount } from 'hooks/useAccount'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
}

// AppUninstalledDialog is shown when the user has an account but has not installed the Shopify App.
export const AppUninstalledDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const { account } = useAccount()

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('common.appUninstalledDialog.title')}
        </Typography>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('common.appUninstalledDialog.description')}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button
            onClick={() => {
              let url = env.SHOPIFY_APP_STORE_URL
              if (account.language === 'ja') url += '?locale=ja'
              window.open(url, '_blank')
            }}
            variant='contained'
          >
            {t('common.appUninstalledDialog.submit')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
