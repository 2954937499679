import { useEffect, useState } from 'react'

export const useTypingEffect = (text: string, speed: number) => {
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setDisplayedText('')
    setIndex(0)
  }, [text])

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text.charAt(index))
        setIndex((prevIndex) => prevIndex + 1)
      }, speed)

      return () => clearTimeout(timer)
    }
  }, [index, text, speed])

  return displayedText
}
