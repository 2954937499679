import { Box, Button, Dialog, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
}

// UpgradeRequiredDialog is used when the user's active customer count exceeds the maximum limit of their current plan.
export const UpgradeRequiredDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('common.upgradeRequiredDialog.title')}
        </Typography>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('common.upgradeRequiredDialog.description')}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)} variant='contained'>
            {t('common.upgradeRequiredDialog.submit')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
