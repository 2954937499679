import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useTheme,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { InsightDrawerState, InsightFilterState, addIndexToName, chartColors, chartColorsDark } from 'features/insights/types/insight'
import { generateQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension, ShopBillingStatus } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useSort } from 'hooks/useSort'
import { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { FreePlanSkeletonText } from './components/freePlanSkeletonText'
import { InsightDrawer } from './components/insightDrawer'
import { NameCheckBoxList } from './components/nameCheckBoxList'
import { ViewType, ViewTypeToggleButton } from './components/viewTypeToggleButton'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '70vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  dimension: InsightDimension
  filter: InsightFilterState
}

type OrderCountInsightValue = {
  rank: number
  name: string
  once: number
  twice: number
  twicePlus: number
  threeTimes: number
  fourTimes: number
  fiveTimesPlus: number
  total: number
  isVisible: boolean
}

export const OrderCountInsight: FC<Props> = ({ dimension, filter }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const insightService = useGrpcClient(InsightService)

  const [viewType, setViewType] = useState<ViewType>(ViewType.visual)
  const [loading, setLoading] = useState(false)
  const [insightValues, setInsightValues] = useState<OrderCountInsightValue[]>()
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })

  const { sortedData: sortedInsightValues, sort, toggleSort } = useSort<OrderCountInsightValue>(insightValues || [])

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const handleChartDataPointClick = (name: string, index: number) => {
    const queryFilters = [
      {
        filter_type: 'order_count' as const,
        operator: 'int_range' as const,
        value: index === 5 ? { min: index } : { min: index, max: index },
      },
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: filter.startDate, max_date: filter.endDate },
      },
    ]
    const segmentQuerySet = customerSegments?.find((segment) => segment.ref.id === filter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(dimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  useEffect(() => {
    const fetchData = async () => {
      if (!filter.startDate || !filter.endDate) return
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await insightService.getOrderCountReport(
          {
            customerSegmentId: filter.customerSegmentId || '',
            dimension: dimension,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setInsightValues(
          resp.values.map((v, i) => ({
            rank: i + 1,
            name: v.name,
            once: Number(v.once),
            twice: Number(v.twice),
            twicePlus: Number(v.twicePlus),
            threeTimes: Number(v.threeTimes),
            fourTimes: Number(v.fourTimes),
            fiveTimesPlus: Number(v.fiveTimesPlus),
            total: Number(v.total),
            isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, filter])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: insightValues
      ?.filter(({ isVisible }) => isVisible)
      .map(({ name, total, once, twicePlus, twice, threeTimes, fourTimes, fiveTimesPlus }) => ({
        name,
        total,
        once,
        twicePlus,
        twice,
        threeTimes,
        fourTimes,
        fiveTimesPlus,
      })),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.customerCount'),
      t('features.insights.detail.orderCountInsight.once'),
      t('features.insights.detail.orderCountInsight.twicePlus'),
      t('features.insights.detail.orderCountInsight.twice'),
      t('features.insights.detail.orderCountInsight.threeTimes'),
      t('features.insights.detail.orderCountInsight.fourTimes'),
      t('features.insights.detail.orderCountInsight.fiveTimesPlus'),
    ],
    fileName: t('features.insights.detail.orderCountInsight.downloadFileName', { dimension: dimension }),
    page: 'insight_' + dimension,
  })

  return (
    <>
      <Box display='flex' justifyContent='flex-end' alignItems='center' marginTop='-52px' marginBottom='16px'>
        <ViewTypeToggleButton viewType={viewType} setViewType={setViewType} />

        <Tooltip title={t('features.insights.detail.download')} placement='top'>
          <span>
            <IconButton
              disabled={!insightValues || insightValues.length === 0 || downloadLoading}
              onClick={() => {
                if (shopBillingStatus === ShopBillingStatus.free) {
                  setUpgradeRecommendDialogOpen(true)
                  return
                }
                handleDownload()
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Paper
                variant='outlined'
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
              >
                <CircularProgress />
              </Paper>
            ) : insightValues.length === 0 ? (
              <Paper
                variant='outlined'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: VISUAL_PAPER_HEIGHT,
                  borderRadius: '12px',
                }}
              >
                <EmptyState title={t('features.insights.detail.noData')} />
              </Paper>
            ) : (
              <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
                <Chart
                  type='bar'
                  height='100%'
                  series={[
                    {
                      name: t('features.insights.detail.orderCountInsight.once'),
                      data: insightValues.filter((v) => selectedNames.includes(v.name)).map((v) => v.once),
                    },
                    {
                      name: t('features.insights.detail.orderCountInsight.twice'),
                      data: insightValues.filter((v) => selectedNames.includes(v.name)).map((v) => v.twice),
                    },
                    {
                      name: t('features.insights.detail.orderCountInsight.threeTimes'),
                      data: insightValues.filter((v) => selectedNames.includes(v.name)).map((v) => v.threeTimes),
                    },
                    {
                      name: t('features.insights.detail.orderCountInsight.fourTimes'),
                      data: insightValues.filter((v) => selectedNames.includes(v.name)).map((v) => v.fourTimes),
                    },
                    {
                      name: t('features.insights.detail.orderCountInsight.fiveTimesPlus'),
                      data: insightValues.filter((v) => selectedNames.includes(v.name)).map((v) => v.fiveTimesPlus),
                    },
                  ]}
                  options={{
                    xaxis: {
                      categories: insightValues
                        .filter((value) => selectedNames.includes(value.name))
                        .map((value) => addIndexToName(value.name, value.rank)),
                      labels: { style: { colors: theme.palette.text.primary } },
                    },
                    yaxis: {
                      labels: { style: { colors: theme.palette.text.primary } },
                    },
                    chart: {
                      stacked: true,
                      stackType: '100%',
                      toolbar: { show: false },
                      animations: { enabled: false },
                      events: {
                        dataPointSelection: (_, __, config) => {
                          const selectedName = insightValues.filter((value) => selectedNames.includes(value.name))[config.dataPointIndex].name
                          const selectedIndex = config.seriesIndex as number
                          handleChartDataPointClick(selectedName, selectedIndex + 1)
                          document.body.style.cursor = 'default'
                        },
                        dataPointMouseEnter: () => {
                          document.body.style.cursor = 'pointer'
                        },
                        dataPointMouseLeave: () => {
                          document.body.style.cursor = 'default'
                        },
                      },
                    },
                    plotOptions: {
                      bar: { horizontal: true },
                    },
                    tooltip: {
                      theme: theme.palette.mode,
                      y: { formatter: (val) => t('features.insights.detail.customers', { count: val }) },
                    },
                    legend: { labels: { colors: theme.palette.text.primary } },
                    colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                  }}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Paper variant='outlined' sx={{ padding: '20px 36px', borderRadius: '12px' }}>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={8} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'total'}
                        direction={sort.target === 'total' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('total')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.customerCount')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'once'}
                        direction={sort.target === 'once' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('once')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.once')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'twicePlus'}
                        direction={sort.target === 'twicePlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('twicePlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.twicePlus')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'twice'}
                        direction={sort.target === 'twice' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('twice')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.twice')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'threeTimes'}
                        direction={sort.target === 'threeTimes' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('threeTimes')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.threeTimes')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fourTimes'}
                        direction={sort.target === 'fourTimes' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fourTimes')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.fourTimes')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fiveTimesPlus'}
                        direction={sort.target === 'fiveTimesPlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fiveTimesPlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.fiveTimesPlus')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedInsightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.total })} placement='top'>
                            <span>{row.total}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.once })} placement='top'>
                            <span>{row.once}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.twicePlus })} placement='top'>
                            <span>{row.twicePlus}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.twice })} placement='top'>
                            <span>{row.twice}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.threeTimes })} placement='top'>
                            <span>{row.threeTimes}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.fourTimes })} placement='top'>
                            <span>{row.fourTimes}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {row.isVisible ? (
                          <Tooltip title={t('features.insights.detail.customers', { count: row.fiveTimesPlus })} placement='top'>
                            <span>{row.fiveTimesPlus}</span>
                          </Tooltip>
                        ) : (
                          <FreePlanSkeletonText />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Paper>
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}

      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
