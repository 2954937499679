import { Box, Dialog } from '@mui/material'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

type Props = {
  open: boolean
  querySetFormMethods: UseFormReturn<QuerySetFormState>
  handleClose: () => void
}

export const PreviewQuerySetDialog: FC<Props> = ({ open, querySetFormMethods, handleClose }) => {
  const [querySet, setQuerySet] = useState<QuerySetFormState>()

  useEffect(() => {
    const fetch = async () => {
      const ok = await querySetFormMethods.trigger()
      if (!ok) return
      const querySetFormValues = querySetFormMethods.getValues()
      setQuerySet(querySetFormValues)
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='32px 40px'>{querySet && <QuerySet querySet={querySet} />}</Box>
    </Dialog>
  )
}
