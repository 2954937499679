import { Box, Card, CardActionArea, CardContent, Chip, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'
import { formatYearMonth } from 'utils/timeUtil'
import { CustomerInsightCard } from './components/customerInsightCard'
import { InsightCategory, insightDimensions } from './types/insightDimensions'

export const Insights = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerSegmentId = searchParams.get('customer_segment_id') || ''

  const [category, setCategory] = useState<InsightCategory>(InsightCategory.all)

  const filteredInsights = useMemo(() => {
    if (category === InsightCategory.all) return insightDimensions
    return insightDimensions.filter((dimension) => dimension.category.includes(category))
  }, [category])

  const generateCohortPath = (basePath: string) => {
    return (
      `${basePath}?start_year_month=${formatYearMonth(dayjs().subtract(1, 'year'))}&end_year_month=${formatYearMonth(dayjs())}` +
      (customerSegmentId.length !== 0 ? `&customer_segment_id=${customerSegmentId}` : '')
    )
  }

  const storeProductInsights = [
    {
      key: 'monthly',
      title: t('features.insights.cohortTitle_monthly'),
      description: t('features.insights.cohortDesc_monthly'),
      path: generateCohortPath(AUTHORIZED_ROUTE.COHORTS_MONTHLY),
    },
    {
      key: 'nth_order',
      title: t('features.insights.cohortTitle_nth_order'),
      description: t('features.insights.cohortDesc_nth_order'),
      path: generateCohortPath(AUTHORIZED_ROUTE.COHORTS_NTH_ORDER),
    },
    {
      key: 'product_insight',
      title: t('features.insights.productInsightTitle'),
      description: t('features.insights.productInsightDesc'),
      path: AUTHORIZED_ROUTE.PRODUCT_INSIGHTS,
    },
  ]

  return (
    <>
      <PageHeader title={t('features.insights.title')} marginBottom='36px' guideType='Insights' />

      <Box marginBottom='32px'>
        <Typography marginBottom='20px'>{t('features.insights.storeProductInsights')}</Typography>
        <Grid container spacing={3} alignItems='stretch'>
          {storeProductInsights.map((item, index) => (
            <Grid key={index} item xs={12} md={4}>
              <Card
                variant='outlined'
                sx={{
                  borderRadius: '12px',
                  height: '100%',
                  '&:hover': {
                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardActionArea onClick={() => navigate(item.path)} sx={{ padding: '4px', height: '100%' }}>
                  <CardContent>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Box
                        component='img'
                        src={theme.palette.mode === 'dark' ? getImagePath(`insights/${item.key}_dark.svg`) : getImagePath(`insights/${item.key}.svg`)}
                        alt={item.key}
                        width='36px'
                      />
                      <Box>
                        <Box display='flex' alignItems='center' gap='8px'>
                          <Typography fontSize='14px'>{item.title}</Typography>
                          {item.key === 'product_insight' && (
                            <Box
                              component='span'
                              sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                borderRadius: '3px',
                                padding: '2px 4px',
                                fontSize: '10px',
                                fontWeight: 'bold',
                              }}
                            >
                              New
                            </Box>
                          )}
                        </Box>
                        <Typography fontSize='13px' color='text.secondary' noWrap textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Typography marginBottom='20px'>{t('features.insights.customerInsights')}</Typography>
        <Box display='flex' alignItems='center' marginBottom='24px' gap='8px'>
          {Object.values(InsightCategory).map((c) => (
            <Chip
              key={c}
              size='small'
              label={t('features.insights.category', { context: c })}
              color={c === category ? 'primary' : 'default'}
              onClick={() => setCategory(c)}
              sx={{ cursor: 'pointer', borderRadius: '6px' }}
            />
          ))}
        </Box>
        <Grid container spacing={3} alignItems='stretch'>
          {!filteredInsights
            ? Array.from(new Array(4)).map((_, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Card sx={{ borderRadius: '12px' }}>
                    <CardActionArea>
                      <Skeleton variant='rectangular' height={120} animation='wave' />
                      <CardContent>
                        <Skeleton width='60%' height={30} animation='wave' />
                        <Skeleton width='90%' height={20} animation='wave' />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            : filteredInsights.map((row) => (
                <Grid key={row.name} item xs={12} sm={6} md={4} lg={3}>
                  <CustomerInsightCard insight={row} customerSegmentId={customerSegmentId} />
                </Grid>
              ))}
        </Grid>
      </Box>
    </>
  )
}
