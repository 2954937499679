import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Avatar, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { useActionTracker } from 'hooks/useMixpanel'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'
import { useIntegrations } from './useIntegrations'

export const Integrations = () => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()

  const { data: integrations } = useIntegrations(i18n.language)

  return (
    <>
      <PageHeader title={t('features.integrations.title')} marginBottom='24px' />

      <TableContainer>
        <Table>
          <TableBody>
            {integrations.map((v) => (
              <TableRow key={v.key}>
                <TableCell width='60px'>
                  <Avatar variant='rounded' alt={v.key} src={getImagePath(`integrations/${v.key}.png`)} />
                </TableCell>
                <TableCell>
                  <Typography fontSize='14px'>{t('features.integrations.name', { context: v.key })}</Typography>
                </TableCell>
                <TableCell align='right' width='200px'>
                  <Button
                    size='small'
                    variant='outlined'
                    endIcon={<OpenInNewIcon />}
                    onClick={() => {
                      dispatch('ViewGuide', {
                        guideType: t('features.integrations.name', { context: v.key }),
                        url: t('features.integrations.url', { context: v.key }),
                      })
                      window.open(t('features.integrations.url', { context: v.key }), '_blank')
                    }}
                  >
                    {t('features.integrations.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
