import { Autocomplete, AutocompleteRenderInputParams, Box, Button, Dialog, FormControl, TextField, Typography } from '@mui/material'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSelect: () => void
  isLoading: boolean
  customerSegments: CustomerSegmentState[] | undefined
  nodes: any[]
  selectedTarget: string[]
  setSelectedTarget: (value: string[]) => void
}

export const AddNodeDialog: FC<Props> = ({ open, onClose, onSelect, isLoading, customerSegments, nodes, selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.customerSegments.group.canvasView.selectSegment')}
        </Typography>

        <Box marginBottom='20px'>
          <FormControl fullWidth>
            <Autocomplete
              loading={isLoading}
              disabled={!customerSegments}
              options={customerSegments?.filter((segment) => !nodes.some((node) => node.id === segment.id)) || []}
              getOptionLabel={(cl) => cl.name || ''}
              onChange={(e, value) => setSelectedTarget(value ? [value.id] : [])}
              value={customerSegments?.find((cl: any) => cl.id === selectedTarget) || null}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField {...params} label={t('features.customerSegments.group.addSegmentDialog.fieldCustomerSegments')} size='small' />
              )}
            />
          </FormControl>
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button onClick={onClose} variant='outlined'>
            {t('features.customerSegments.group.canvasView.cancel')}
          </Button>
          <Button onClick={onSelect} disabled={selectedTarget.length === 0} variant='contained'>
            {t('features.customerSegments.group.canvasView.add')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
