export type guideType =
  | 'DashboardHeader'
  | 'UpgradeFeature'
  | 'CustomerSegmentOverview'
  | 'CustomerSegmentCreate'
  | 'CustomerSegmentDetail'
  | 'CustomerSegmentPastDataReproduction'
  | 'CustomerDetail'
  | 'CustomerJourney'
  | 'TagOperation'
  | 'SegmentShowcase'
  | 'MonthlyCohort'
  | 'NthOrderCohort'
  | 'CustomerMetricsCount'
  | 'CustomerMetricsRepeaterRate'
  | 'CustomerMetricsLtv'
  | 'CustomerMetricsAvgOrderValue'
  | 'CustomerMetricsAvgOrderCount'
  | 'CustomerMetricsAvgDaysBetweenOrders'
  | 'Insights'
  | 'InsightProduct'
  | 'InsightProductType'
  | 'InsightProductVendor'
  | 'InsightProductTag'
  | 'InsightReferrer'
  | 'InsightLandingPage'
  | 'InsightUtm'
  | 'InsightChannel'
  | 'InsightOrderTag'
  | 'InsightCoupon'
  | 'InsightYearMonth'
  | 'InsightWeekday'
  | 'InsightHour'
  | 'InsightIntervalDays'
  | 'InsightLifetimeDays'
  | 'InsightAov'
  | 'InsightRfm'
  | 'InsightLocation'
  | 'InsightCustomerTag'
  | 'Recipes'

export const guideMap: Record<string, Record<guideType, string>> = {
  ja: {
    DashboardHeader: 'https://www.ec-power.io/ja-jp/guide',
    UpgradeFeature: 'https://www.ec-power.io/ja-jp/guide/billing-and-plans',
    CustomerSegmentOverview: 'https://www.ec-power.io/ja-jp/guide/overview-customer-segment',
    CustomerSegmentCreate: 'https://www.ec-power.io/ja-jp/guide/create-customer-segment',
    CustomerSegmentDetail: 'https://www.ec-power.io/ja-jp/guide/detail-customer-segment',
    CustomerSegmentPastDataReproduction: 'https://www.ec-power.io/ja-jp/release/notice-reproduce-past-segment-data',
    CustomerDetail: 'https://www.ec-power.io/ja-jp/guide/customer-detail',
    CustomerJourney: 'https://www.ec-power.io/ja-jp/guide/customer-journey',
    TagOperation: 'https://www.ec-power.io/ja-jp/guide/shopify-customer-tag',
    SegmentShowcase: 'https://www.ec-power.io/ja-jp/guide/template-showcase',
    MonthlyCohort: 'https://www.ec-power.io/ja-jp/guide/cohorts-vs-month',
    NthOrderCohort: 'https://www.ec-power.io/ja-jp/guide/cohorts-vs-nth',
    CustomerMetricsCount: 'https://www.ec-power.io/ja-jp/guide/segment-size',
    CustomerMetricsRepeaterRate: 'https://www.ec-power.io/ja-jp/guide/repeat-customer-rate',
    CustomerMetricsLtv: 'https://www.ec-power.io/ja-jp/guide/lifetime-value',
    CustomerMetricsAvgOrderValue: 'https://www.ec-power.io/ja-jp/guide/average-order-value',
    CustomerMetricsAvgOrderCount: 'https://www.ec-power.io/ja-jp/guide/average-order-count',
    CustomerMetricsAvgDaysBetweenOrders: 'https://www.ec-power.io/ja-jp/guide/purchase-intervals',
    Insights: 'https://www.ec-power.io/ja-jp/guide/features',
    InsightProduct: 'https://www.ec-power.io/ja-jp/guide/popular-products',
    InsightProductType: 'https://www.ec-power.io/ja-jp/guide/popular-product-types',
    InsightProductVendor: 'https://www.ec-power.io/ja-jp/guide/popular-product-vendors',
    InsightProductTag: 'https://www.ec-power.io/ja-jp/guide/popular-product-tags',
    InsightReferrer: 'https://www.ec-power.io/ja-jp/guide/popular-referrers',
    InsightLandingPage: 'https://www.ec-power.io/ja-jp/guide/popular-landing-pages',
    InsightUtm: 'https://www.ec-power.io/ja-jp/guide/popular-utm-parameters',
    InsightChannel: 'https://www.ec-power.io/ja-jp/guide/popular-sales-channels',
    InsightOrderTag: 'https://www.ec-power.io/ja-jp/guide/popular-order-tags',
    InsightCoupon: 'https://www.ec-power.io/ja-jp/guide/popular-coupons',
    InsightYearMonth: 'https://www.ec-power.io/ja-jp/guide/popular-order-year-month',
    InsightWeekday: 'https://www.ec-power.io/ja-jp/guide/busiest-weekday',
    InsightHour: 'https://www.ec-power.io/ja-jp/guide/busiest-order-time',
    InsightIntervalDays: 'https://www.ec-power.io/ja-jp/guide/purchase-interval-distributions',
    InsightLifetimeDays: 'https://www.ec-power.io/ja-jp/guide/lifetime-duration-distributions',
    InsightAov: 'https://www.ec-power.io/ja-jp/guide/aov-distributions',
    InsightRfm: 'https://www.ec-power.io/ja-jp/guide/rfm-status-distributions',
    InsightLocation: 'https://www.ec-power.io/ja-jp/guide/popular-locations',
    InsightCustomerTag: 'https://www.ec-power.io/ja-jp/guide/popular-customer-tags',
    Recipes: 'https://www.ec-power.io/ja-jp/category/recipes',
  },
  en: {
    DashboardHeader: 'https://www.ec-power.io/guide',
    UpgradeFeature: 'https://www.ec-power.io/guide/billing-and-plans',
    CustomerSegmentOverview: 'https://www.ec-power.io/guide/overview-customer-segment',
    CustomerSegmentCreate: 'https://www.ec-power.io/guide/create-customer-segment',
    CustomerSegmentDetail: 'https://www.ec-power.io/guide/detail-customer-segment',
    CustomerSegmentPastDataReproduction: 'https://www.ec-power.io/guide/reproduce-metric-history',
    CustomerDetail: 'https://www.ec-power.io/guide/customer-detail',
    CustomerJourney: 'https://www.ec-power.io/guide/customer-journey',
    TagOperation: 'https://www.ec-power.io/guide/shopify-customer-tag',
    SegmentShowcase: 'https://www.ec-power.io/guide/template-showcase',
    MonthlyCohort: 'https://www.ec-power.io/guide/cohorts-vs-month',
    NthOrderCohort: 'https://www.ec-power.io/guide/cohorts-vs-nth',
    CustomerMetricsCount: 'https://www.ec-power.io/guide/segment-size',
    CustomerMetricsRepeaterRate: 'https://www.ec-power.io/guide/repeat-customer-rate',
    CustomerMetricsLtv: 'https://www.ec-power.io/guide/lifetime-value',
    CustomerMetricsAvgOrderValue: 'https://www.ec-power.io/guide/average-order-value',
    CustomerMetricsAvgOrderCount: 'https://www.ec-power.io/guide/average-order-count',
    CustomerMetricsAvgDaysBetweenOrders: 'https://www.ec-power.io/guide/purchase-intervals',
    Insights: 'https://www.ec-power.io/guide/features',
    InsightProduct: 'https://www.ec-power.io/guide/popular-products',
    InsightProductType: 'https://www.ec-power.io/guide/popular-product-types',
    InsightProductVendor: 'https://www.ec-power.io/guide/popular-product-vendors',
    InsightProductTag: 'https://www.ec-power.io/guide/popular-product-tags',
    InsightReferrer: 'https://www.ec-power.io/guide/popular-referrers',
    InsightLandingPage: 'https://www.ec-power.io/guide/popular-landing-pages',
    InsightUtm: 'https://www.ec-power.io/guide/popular-utm-parameters',
    InsightChannel: 'https://www.ec-power.io/guide/popular-sales-channels',
    InsightOrderTag: 'https://www.ec-power.io/guide/popular-order-tags',
    InsightCoupon: 'https://www.ec-power.io/guide/popular-coupons',
    InsightYearMonth: 'https://www.ec-power.io/guide/popular-order-year-month',
    InsightWeekday: 'https://www.ec-power.io/guide/busiest-weekday',
    InsightHour: 'https://www.ec-power.io/guide/busiest-order-time',
    InsightIntervalDays: 'https://www.ec-power.io/guide/purchase-interval-distributions',
    InsightLifetimeDays: 'https://www.ec-power.io/guide/lifetime-duration-distributions',
    InsightAov: 'https://www.ec-power.io/guide/aov-distributions',
    InsightRfm: 'https://www.ec-power.io/guide/rfm-status-distributions',
    InsightLocation: 'https://www.ec-power.io/guide/popular-locations',
    InsightCustomerTag: 'https://www.ec-power.io/guide/popular-customer-tags',
    Recipes: 'https://www.ec-power.io/category/recipes',
  },
}
