import { Box, Button, Dialog, Link, Typography } from '@mui/material'
import { guideMap } from 'config/guide'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
}

export const PastDataReproductionDialog: FC<Props> = ({ open, handleClose }) => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.customerSegments.detail.pastDataReproductionDialog.title')}
        </Typography>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('features.customerSegments.detail.pastDataReproductionDialog.description1')}
          <Link
            onClick={() => {
              const guideType = 'CustomerSegmentPastDataReproduction'
              const guideURL = guideMap[i18n.language][guideType]
              dispatch('ViewGuide', { guideType: guideType, url: guideURL })
              window.open(guideURL, '_blank')
            }}
            sx={{ cursor: 'pointer' }}
          >
            {t('features.customerSegments.detail.pastDataReproductionDialog.urlText')}
          </Link>
          {t('features.customerSegments.detail.pastDataReproductionDialog.description2')}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.detail.pastDataReproductionDialog.cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
