import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Alert, AlertTitle, Box, Chip, CircularProgress, Grid, LinearProgress, Paper, Tooltip, Typography } from '@mui/material'
import { GuideIcon } from 'components/guideIcon'
import { billingStatus, isPlanSelectable, isUpgradeRequired, useUpgradeFeatureList } from 'config/plan'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier, Shop, ShopBillingStatus } from 'gen/firestore'
import { BillingService } from 'gen/proto/billing/billing_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AUTHORIZED_ROUTE } from 'routing'
import ChannelTalkClient from 'utils/channelTalk'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'
import { DowngradeToFreeDialog } from './components/downgradeToFreeDialog'
import { PlanItem } from './components/planItem'

type Props = {
  shop: Shop
}

export const Plan: FC<Props> = ({ shop }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const { enqueueSnackbar } = useCustomSnackbar()
  const billingService = useGrpcClient(BillingService)
  const shopBillingStatus = billingStatus(shop, dayjs())

  const upgradeFeatureList = useUpgradeFeatureList()

  const [syncing, setSyncing] = useState(false)

  const activeCustomerPercentage = useMemo(() => {
    return (shop.activeCustomerCount / shop.billingPlan.maxActiveCustomerCount) * 100
  }, [shop])

  useEffect(() => {
    const syncActivePlanFromShopify = async () => {
      setSyncing(true)
      try {
        const token = await getIdToken(authUser!)
        await billingService.syncActivePlanFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
      } catch (err) {
        notifySentry(err)
      } finally {
        setSyncing(false)
      }
    }

    syncActivePlanFromShopify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [downgradeToFreeDialogOpen, setDowngradeToFreeDialogOpen] = useState(false)
  const [loadingManager, setLoadingManager] = useState({
    [BillingPlanIdentifier.free]: false,
    [BillingPlanIdentifier.starter]: false,
    [BillingPlanIdentifier.growth]: false,
    [BillingPlanIdentifier.scale]: false,
  })

  const changeToFreePlan = async () => {
    // When downgrading to the free plan, a confirmation modal will be displayed and the process will be delegated to it.
    setDowngradeToFreeDialogOpen(true)
  }

  const changeToPaidPlan = async (plan: BillingPlanIdentifier) => {
    setLoadingManager((prev) => ({ ...prev, [plan]: true }))
    try {
      const token = await getIdToken(authUser!)
      const resp = await billingService.startChangePlanFlow(
        {
          plan: plan,
          returnURL: `${window.location.origin}${AUTHORIZED_ROUTE.SETTINGS_PLAN}`,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // Redirect to Shopify Admin Dashboard
      window.location.href = resp.confirmURL
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.messageError'), { severity: 'error' })
      notifySentry(err)
      setLoadingManager((prev) => ({ ...prev, [plan]: false }))
    }
  }

  const getChangePlanFunc = (plan: BillingPlanIdentifier) => {
    if (plan === BillingPlanIdentifier.free) return changeToFreePlan
    return () => changeToPaidPlan(plan)
  }

  return (
    <>
      {syncing ? (
        <CircularProgress />
      ) : (
        <>
          {shopBillingStatus === ShopBillingStatus.trial && (
            <Paper
              sx={{
                padding: '32px',
                marginBottom: '24px',
                borderRadius: '8px',
                backgroundColor: (theme) => theme.palette.primary.main + '33',
                borderColor: (theme) => theme.palette.primary.main + '33',
              }}
              variant='outlined'
            >
              <Typography fontWeight='bold' marginBottom='8px'>
                {t('features.settings.plan.nowTrialTitle')}
              </Typography>
              <Typography fontSize='14px'>
                {t('features.settings.plan.nowTrialDescription', { date: formatDate(timestampToDayjs(shop.trialEndsAt), i18n.language) })}
              </Typography>
            </Paper>
          )}

          {isUpgradeRequired(shop) && (
            <Alert severity='error' sx={{ borderRadius: '8px', padding: '16px', marginBottom: '24px' }}>
              <AlertTitle>{t('features.settings.plan.upgradeIsRequiredTitle')}</AlertTitle>
              {t('features.settings.plan.upgradeIsRequiredDescription')}
            </Alert>
          )}

          <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
            <Typography fontWeight='bold' marginBottom='16px'>
              {t('features.settings.plan.title')}
            </Typography>

            <Grid container spacing={1} paddingLeft='4px'>
              <Grid fontSize='14px' item xs={2}>
                {t('features.settings.plan.currentPlan')}
              </Grid>
              <Grid fontSize='14px' item xs={10}>
                <Box display='flex' alignItems='center'>
                  <Typography fontSize='inherit' marginRight='4px'>
                    {t('features.settings.plan.name', { context: shop.billingPlan.identifier })}
                  </Typography>
                  <Typography fontSize='inherit'>{t('features.settings.plan.price_amount', { amount: shop.billingPlan.amount })}</Typography>
                  {shop.isDemo && <Chip size='small' label={t('features.settings.plan.demo')} sx={{ borderRadius: '4px', marginLeft: '8px' }} />}
                  {shopBillingStatus === ShopBillingStatus.trial && (
                    <Chip size='small' label={t('features.settings.plan.trial')} sx={{ borderRadius: '4px', marginLeft: '8px' }} />
                  )}
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box display='flex' alignItems='center'>
                  <Typography fontSize='14px'>{t('features.settings.plan.activeCustomers')}</Typography>
                  <Tooltip title={t('features.settings.plan.activeCustomersDescription')}>
                    <HelpOutlineIcon sx={{ marginLeft: '2px', fontSize: '12px', color: (theme) => theme.palette.text.secondary }} />
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={10}>
                {shopBillingStatus === ShopBillingStatus.paid ? (
                  <Box display='flex' alignItems='center' gap='20px'>
                    <Typography fontSize='14px'>
                      {t('features.settings.plan.activeCustomersPercentage', {
                        current: shop.activeCustomerCount.toLocaleString(),
                        max: shop.billingPlan.maxActiveCustomerCount.toLocaleString(),
                        percentage: activeCustomerPercentage.toFixed(0),
                      })}
                    </Typography>
                    <LinearProgress
                      variant='determinate'
                      value={activeCustomerPercentage}
                      sx={{ width: '120px', height: '8px', borderRadius: '4px' }}
                    />
                  </Box>
                ) : (
                  <Typography fontSize='14px'>
                    {t('features.settings.plan.activeCustomersCounter', { current: shop.activeCustomerCount.toLocaleString() })}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>

          {shopBillingStatus !== ShopBillingStatus.paid && (
            <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
              <Box display='flex' alignItems='center' gap='2px' marginBottom='16px'>
                <Typography fontWeight='bold'>{t('features.settings.plan.upgradePlanFeatureTitle')}</Typography>
                <GuideIcon guideType='UpgradeFeature' />
              </Box>
              <Grid container spacing={2}>
                {upgradeFeatureList.map((feature, index) => (
                  <Grid item xs={6} key={index}>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Typography fontSize='24px'>{feature.icon}</Typography>
                      <Box width='92%'>
                        <Typography fontSize='14px'>{feature.title}</Typography>
                        <Tooltip title={feature.description} placement='bottom-end'>
                          <Typography
                            fontSize='12px'
                            color='text.secondary'
                            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          >
                            {feature.description}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}

          {shop.billingPlan.identifier !== BillingPlanIdentifier.enterprise && (
            <Box>
              <Grid container spacing={2} justifyContent='space-between' alignItems='stretch'>
                <Grid item xs={3}>
                  <PlanItem
                    plan={BillingPlanIdentifier.free}
                    selected={shop.billingPlan.identifier === BillingPlanIdentifier.free}
                    selectable={isPlanSelectable(shop, BillingPlanIdentifier.free)}
                    onChangePlan={getChangePlanFunc(BillingPlanIdentifier.free)}
                    submitting={loadingManager.free}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PlanItem
                    plan={BillingPlanIdentifier.starter}
                    selected={shop.billingPlan.identifier === BillingPlanIdentifier.starter}
                    selectable={isPlanSelectable(shop, BillingPlanIdentifier.starter)}
                    onChangePlan={getChangePlanFunc(BillingPlanIdentifier.starter)}
                    submitting={loadingManager.starter}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PlanItem
                    plan={BillingPlanIdentifier.growth}
                    selected={shop.billingPlan.identifier === BillingPlanIdentifier.growth}
                    selectable={isPlanSelectable(shop, BillingPlanIdentifier.growth)}
                    onChangePlan={getChangePlanFunc(BillingPlanIdentifier.growth)}
                    submitting={loadingManager.growth}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PlanItem
                    plan={BillingPlanIdentifier.scale}
                    selected={shop.billingPlan.identifier === BillingPlanIdentifier.scale}
                    selectable={isPlanSelectable(shop, BillingPlanIdentifier.scale)}
                    onChangePlan={getChangePlanFunc(BillingPlanIdentifier.scale)}
                    submitting={loadingManager.scale}
                  />
                </Grid>
              </Grid>

              <Typography fontSize='14px' color={(theme) => theme.palette.text.secondary} marginTop='24px'>
                {t('features.settings.plan.description', { context: 'enterprise' })}
                <span
                  onClick={() => {
                    ChannelTalkClient.openChat()
                  }}
                  color='primary'
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {t('features.settings.plan.contactSupport')}
                </span>
              </Typography>

              {downgradeToFreeDialogOpen && (
                <DowngradeToFreeDialog open={downgradeToFreeDialogOpen} handleClose={() => setDowngradeToFreeDialogOpen(false)} />
              )}
            </Box>
          )}
        </>
      )}
    </>
  )
}
