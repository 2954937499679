import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
}

export const DeleteNodeDialog: FC<Props> = ({ open, handleClose, handleSubmit }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.customerSegments.group.deleteNodeDialog.title')}
        </Typography>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('features.customerSegments.group.deleteNodeDialog.description')}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.group.deleteNodeDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loadingPosition='center' onClick={handleSubmit} variant='contained'>
            {t('features.customerSegments.group.deleteNodeDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
