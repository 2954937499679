import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { defaultQueryItem } from '../defalutValues'
import { FilterItems } from '../filterItem/filterItems'
import { QueryItem } from '../queryItem/queryItem'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  querySubsetPath: `query_subsets.${number}`
}

export const QuerySubset: FC<Props> = ({ querySubsetPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { fields, append, remove } = useFieldArray<QuerySetFormState>({
    control,
    name: `${querySubsetPath}.query_items`,
  })

  return (
    <Box>
      {fields.map((field, queryItemIndex) => (
        <div key={field.id}>
          <Controller
            control={control}
            name={`${querySubsetPath}.join_operator`}
            render={({ field, fieldState: { error } }) => {
              return (
                <Box display={queryItemIndex === 0 ? 'none' : 'flex'} alignItems='center' sx={{ margin: '12px 0px' }}>
                  <FormControl sx={{ margin: '18px 0px', display: queryItemIndex === 0 ? 'none' : 'block' }}>
                    <Select
                      defaultValue='and'
                      error={Boolean(error)}
                      value={field.value}
                      onChange={field.onChange}
                      size='small'
                      sx={{ backgroundColor: (theme) => theme.palette.background.default }}
                    >
                      <MenuItem key='and' value='and'>
                        {t('features.customerSegments.querySet.joinOperator', { context: 'and' })}
                      </MenuItem>
                      <MenuItem key='or' value='or'>
                        {t('features.customerSegments.querySet.joinOperator', { context: 'or' })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Divider sx={{ width: '100%', height: '100%' }} />
                </Box>
              )
            }}
          />
          <Box display='flex' alignItems='center'>
            <QueryItem queryItemPath={`${querySubsetPath}.query_items.${queryItemIndex}`} />
            <IconButton size='small' disabled={fields.length === 1} onClick={() => remove(queryItemIndex)} sx={{ marginLeft: '8px' }}>
              <CancelOutlinedIcon fontSize='small' />
            </IconButton>
          </Box>
          <FilterItems open={true} queryItemPath={`${querySubsetPath}.query_items.${queryItemIndex}`} />
        </div>
      ))}
      <Button
        variant='text'
        size='small'
        startIcon={<AddBoxOutlinedIcon />}
        disabled={fields.length >= 10}
        onClick={() => append(defaultQueryItem)}
        sx={{ marginTop: '12px' }}
      >
        {t('features.customerSegments.querySet.querySetForm.addQueryItem')}
      </Button>
    </Box>
  )
}
