import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { auth } from 'config/firebaseClient'
import { sendPasswordResetEmail } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_ROUTE } from 'routing'

type Props = {
  open: boolean
  account: Account
  handleClose: () => void
}

export const ResetPasswordDialog: FC<Props> = ({ open, account, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}${PUBLIC_ROUTE.LOGIN}`,
      }
      auth.languageCode = account.language
      await sendPasswordResetEmail(auth, account.email, actionCodeSettings)
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: account.email })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.settings.myAccount.resetPasswordDialog.title')}
        </Typography>
        <Typography fontSize='14px' color='text.secondary' marginBottom='20px'>
          {t('features.settings.myAccount.resetPasswordDialog.description', { email: account.email })}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={loading} onClick={handleClose} variant='outlined'>
            {t('features.settings.myAccount.resetPasswordDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={loading} loadingPosition='center' onClick={handleSubmit} variant='contained'>
            {t('features.settings.myAccount.resetPasswordDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
