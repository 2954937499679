import { Box } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_RELATIVE_DATE_RANGE } from '../../querySetForm/schema/const'
import { QueryItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { DimensionChip } from '../dimensionChip'
import { OperatorChip } from '../operatorChip'
import { ValueChip } from '../valueChip'

type Props = {
  queryItem: QueryItemFormState
  icon: JSX.Element
}

export const OrderDateQuery: FC<Props> = ({ queryItem, icon }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box component='span' marginRight='6px' fontSize='14px'>
        {t('features.customerSegments.querySet.querySetView.prefixForOrder')}
      </Box>

      <DimensionChip label={t('features.customerSegments.querySet.dimension', { context: queryItem.dimension })} icon={icon} />
      <OperatorChip label={t('features.customerSegments.querySet.querySetView.op_order', { context: queryItem.operator })} />

      {queryItem.operator === OP_DATE_RANGE && (
        <>
          {queryItem.value.min_date && <ValueChip label={queryItem.value.min_date} />}
          {queryItem.value.min_date && queryItem.value.max_date && queryItem.value.min_date !== queryItem.value.max_date && (
            <OperatorChip label='~' />
          )}
          {queryItem.value.min_date && !queryItem.value.max_date && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orAfter')} />
          )}
          {queryItem.value.max_date && queryItem.value.min_date !== queryItem.value.max_date && <ValueChip label={queryItem.value.max_date} />}
          {!queryItem.value.min_date && queryItem.value.max_date && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orBefore')} />
          )}
        </>
      )}

      {queryItem.operator === OP_RELATIVE_DATE_RANGE && (
        <>
          {queryItem.value.max && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: queryItem.value.max })} />
          )}
          {!queryItem.value.min && queryItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orAfter')} />}
          {queryItem.value.min && queryItem.value.max && queryItem.value.min !== queryItem.value.max && <OperatorChip label='~' />}
          {queryItem.value.min && queryItem.value.min !== queryItem.value.max && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: queryItem.value.min })} />
          )}
          {queryItem.value.min && !queryItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orBefore')} />}
        </>
      )}
    </>
  )
}
