import CloseIcon from '@mui/icons-material/Close'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import { Box, Button, FormControl, IconButton, MenuItem, Paper, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { defaultQuerySubset } from './defalutValues'
import { QuerySubset } from './querySubset/querySubset'
import { QuerySetFormState } from './schema/querySetFormSchema'

export const QuerySetForm: FC = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { fields, append, remove } = useFieldArray<QuerySetFormState>({
    control: control,
    name: 'query_subsets',
  })

  return (
    <>
      <Box>
        {fields.map((field, querySubsetIndex) => (
          <div key={field.id}>
            <Controller
              control={control}
              name={`join_operator`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl sx={{ minWidth: '120px', margin: '12px 0px', display: querySubsetIndex === 0 ? 'none' : 'block' }}>
                    <Select defaultValue='and' error={Boolean(error)} value={field.value} onChange={field.onChange} size='small'>
                      <MenuItem key='and' value='and'>
                        {t('features.customerSegments.querySet.joinOperator', { context: 'and' })}
                      </MenuItem>
                      <MenuItem key='or' value='or'>
                        {t('features.customerSegments.querySet.joinOperator', { context: 'or' })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )
              }}
            />
            <Paper
              variant='outlined'
              sx={{
                position: 'relative',
                padding: '32px',
                borderRadius: '6px',
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <QuerySubset querySubsetPath={`query_subsets.${querySubsetIndex}`} />
              <IconButton
                size='small'
                disabled={fields.length === 1}
                onClick={() => remove(querySubsetIndex)}
                sx={{
                  position: 'absolute',
                  right: '16px',
                  top: '16px',
                }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Paper>
          </div>
        ))}
      </Box>
      <Button
        variant='outlined'
        size='small'
        startIcon={<LibraryAddOutlinedIcon />}
        disabled={fields.length >= 3}
        onClick={() => append(defaultQuerySubset)}
        sx={{ marginTop: '12px' }}
      >
        {t('features.customerSegments.querySet.querySetForm.addQuerySubset')}
      </Button>
    </>
  )
}
