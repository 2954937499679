import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { Box, Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { useInsights } from '../hooks/useInsights'

type Props = {
  customerSegment: CustomerSegmentState | undefined
}

export const Insights: FC<Props> = ({ customerSegment }) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()

  const { productInsightValues, customerTagInsightValues, recencyInsightValue, overlapCustomerSegments } = useInsights(customerSegment?.id)

  return (
    <Grid container spacing={3} alignItems='stretch'>
      <Grid item xs={12}>
        <Paper
          variant='outlined'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '8px',
            border: 'none',
            padding: '16px 24px',
            backgroundColor: (theme) => theme.palette.primary.main + '33',
            color: 'black',
          }}
        >
          <Box display='flex' alignItems='center'>
            <LightbulbRoundedIcon fontSize='small' color='primary' sx={{ marginRight: '12px' }} />
            <Typography fontSize='14px'>{t('features.customerSegments.detail.insights.alert')}</Typography>
          </Box>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              dispatch('ClickViewInsights', { customerSegment: customerSegment?.name })
              window.open(`${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${customerSegment?.id}`, '_blank')
            }}
          >
            {t('features.customerSegments.detail.insights.viewInsights')}
          </Button>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px', height: '100%' }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='32px'>
            <Typography>{t('features.customerSegments.detail.insights.overlapSegmentsTitle')}</Typography>
          </Box>
          {!overlapCustomerSegments ? (
            <SkeletonTable rowCount={3} columnCount={3} />
          ) : overlapCustomerSegments.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
          ) : (
            <Grid container spacing={2.5} sx={{ paddingX: '8px' }}>
              {overlapCustomerSegments.map((v) => (
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Typography fontSize='20px'>{extractIconFromTextWithIcon(v.name)}</Typography>
                      <Typography fontSize='14px'>{extractTextFromTextWithIcon(v.name)}</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                        <LocalFireDepartmentRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px', color: 'orange' }} />
                        {v.overlapRatio}%
                      </Typography>
                      <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                        <PersonRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px' }} />
                        {v.customerCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px', height: '100%' }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='32px'>
            <Typography>{t('features.customerSegments.detail.insights.recencyTitle')}</Typography>
            <Button
              size='small'
              endIcon={<ArrowForwardIcon fontSize='small' />}
              sx={{ padding: 0 }}
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: 'recency' }) + `?customer_segment_id=${customerSegment?.id}`
                window.open(to, '_blank')
              }}
            >
              {t('features.customerSegments.detail.insights.viewMore')}
            </Button>
          </Box>
          {!recencyInsightValue ? (
            <SkeletonTable rowCount={3} columnCount={3} />
          ) : recencyInsightValue.values.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
          ) : (
            <TableContainer>
              <Table size='small'>
                <TableBody>
                  {recencyInsightValue.values.map((v) => (
                    <TableRow key={`${v.min}-${v.max}`}>
                      <TableCell sx={{ whiteSpace: 'nowrap', borderBottom: 'none' }}>
                        <Typography fontSize='14px'>
                          {t('features.customerSegments.detail.insights.recencyDays', { min: v.min, max: v.max })}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: '100%', borderBottom: 'none' }}>
                        <Box display='flex' alignItems='center' gap='12px'>
                          <LinearProgress
                            variant='determinate'
                            value={(v.customerCount / Math.max(...recencyInsightValue.values.map((val) => val.customerCount))) * 100}
                            sx={{
                              borderRadius: '8px',
                              height: '10px',
                              flexGrow: 1,
                            }}
                          />
                          <Typography fontSize='13px' color='text.secondary'>
                            {v.customerCount}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px', height: '100%' }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='32px'>
            <Typography>{t('features.customerSegments.detail.insights.customerTagsTitle')}</Typography>
            <Button
              size='small'
              sx={{ padding: 0 }}
              endIcon={<ArrowForwardIcon fontSize='small' />}
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: 'customer_tag' }) + `?customer_segment_id=${customerSegment?.id}`
                window.open(to, '_blank')
              }}
            >
              {t('features.customerSegments.detail.insights.viewMore')}
            </Button>
          </Box>
          {!customerTagInsightValues ? (
            <SkeletonTable rowCount={3} columnCount={3} />
          ) : customerTagInsightValues.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
          ) : (
            <Grid container spacing={2.5} sx={{ paddingX: '8px' }}>
              {customerTagInsightValues.map((v) => (
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Typography fontSize='20px'>{extractIconFromTextWithIcon(v.name)}</Typography>
                      <Typography fontSize='14px'>{extractTextFromTextWithIcon(v.name)}</Typography>
                    </Box>
                    <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                      <LocalFireDepartmentRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px', color: 'orange' }} />
                      {v.ratio}%
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px', height: '100%' }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='32px'>
            <Typography>{t('features.customerSegments.detail.insights.productsTitle')}</Typography>
            <Button
              size='small'
              endIcon={<ArrowForwardIcon fontSize='small' />}
              sx={{ padding: 0 }}
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: 'product' }) + `?customer_segment_id=${customerSegment?.id}`
                window.open(to, '_blank')
              }}
            >
              {t('features.customerSegments.detail.insights.viewMore')}
            </Button>
          </Box>
          {!productInsightValues ? (
            <SkeletonTable rowCount={3} columnCount={3} />
          ) : productInsightValues.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.insights.empty')} />
          ) : (
            <Grid container spacing={2.5} sx={{ paddingX: '8px' }}>
              {productInsightValues.map((v) => (
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' gap='12px'>
                      <Typography fontSize='20px'>{extractIconFromTextWithIcon(v.name)}</Typography>
                      <Typography fontSize='14px'>{extractTextFromTextWithIcon(v.name)}</Typography>
                    </Box>
                    <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                      <LocalFireDepartmentRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px', color: 'orange' }} />
                      {v.ratio}%
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
