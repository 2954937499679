import AirplaneTicketRoundedIcon from '@mui/icons-material/AirplaneTicketRounded'
import { Box, Button, Grid, LinearProgress, Paper, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'

type Props = {
  shopBillingStatus: ShopBillingStatus
}

export const UpgradeBox: FC<Props> = ({ shopBillingStatus }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const totalTrialDays = timestampToDayjs(shop.trialEndsAt).diff(timestampToDayjs(shop.createdAt), 'day')
  const elapsedTrialDays = dayjs().diff(timestampToDayjs(shop.createdAt), 'day')
  const remainingTrialDays = totalTrialDays - elapsedTrialDays

  return (
    <Paper
      variant='outlined'
      sx={{
        padding: '16px',
        backgroundColor: (theme) => theme.palette.primary.main + '1A',
      }}
    >
      <Grid spacing={1.5} container>
        <Grid item xs={12}>
          <Box display='flex' alignItems='center'>
            <AirplaneTicketRoundedIcon fontSize='small' color='primary' />
            <Typography fontSize='14px' fontWeight='bold' marginLeft='8px'>
              {t('layouts.dashboardLayout.upgradeTitle')}
            </Typography>
          </Box>
        </Grid>

        {shopBillingStatus === 'trial' && (
          <Grid item xs={12}>
            <Typography fontSize='12px' color='text.secondary' marginBottom='12px'>
              {t('layouts.dashboardLayout.upgradeDescTrial', { date: formatDate(timestampToDayjs(shop.trialEndsAt!), i18n.language) })}
            </Typography>
            <Box>
              <LinearProgress
                variant='determinate'
                value={(elapsedTrialDays / totalTrialDays) * 100}
                sx={{ marginBottom: '4px', borderRadius: '4px', height: '8px' }}
              />
              <Typography fontSize='12px' color='text.secondary'>
                {t('layouts.dashboardLayout.upgradeProgress', { remaining: remainingTrialDays, total: totalTrialDays })}
              </Typography>
            </Box>
          </Grid>
        )}

        {shopBillingStatus === 'free' && (
          <Grid item xs={12}>
            <Typography fontSize='12px' color='text.secondary'>
              {t('layouts.dashboardLayout.upgradeDescFree')}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant='contained'
            size='small'
            sx={{ width: '100%' }}
            onClick={() => {
              dispatch('ClickCheckPlan', { referrer: 'sidebar' })
              navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
            }}
          >
            {t('layouts.dashboardLayout.upgradeCTA')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
