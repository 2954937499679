// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/product/product.proto (package product, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64} from "@bufbuild/protobuf";

/**
 * GetPreferredSegments
 *
 * @generated from message product.GetPreferredSegmentsRequest
 */
export class GetPreferredSegmentsRequest extends Message<GetPreferredSegmentsRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetPreferredSegmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetPreferredSegmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreferredSegmentsRequest {
    return new GetPreferredSegmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreferredSegmentsRequest {
    return new GetPreferredSegmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreferredSegmentsRequest {
    return new GetPreferredSegmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreferredSegmentsRequest | PlainMessage<GetPreferredSegmentsRequest> | undefined, b: GetPreferredSegmentsRequest | PlainMessage<GetPreferredSegmentsRequest> | undefined): boolean {
    return proto3.util.equals(GetPreferredSegmentsRequest, a, b);
  }
}

/**
 * @generated from message product.GetPreferredSegmentsResponse
 */
export class GetPreferredSegmentsResponse extends Message<GetPreferredSegmentsResponse> {
  /**
   * @generated from field: repeated product.GetPreferredSegmentsResponse.Segment segments = 1;
   */
  segments: GetPreferredSegmentsResponse_Segment[] = [];

  constructor(data?: PartialMessage<GetPreferredSegmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetPreferredSegmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segments", kind: "message", T: GetPreferredSegmentsResponse_Segment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreferredSegmentsResponse {
    return new GetPreferredSegmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreferredSegmentsResponse {
    return new GetPreferredSegmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreferredSegmentsResponse {
    return new GetPreferredSegmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreferredSegmentsResponse | PlainMessage<GetPreferredSegmentsResponse> | undefined, b: GetPreferredSegmentsResponse | PlainMessage<GetPreferredSegmentsResponse> | undefined): boolean {
    return proto3.util.equals(GetPreferredSegmentsResponse, a, b);
  }
}

/**
 * @generated from message product.GetPreferredSegmentsResponse.Segment
 */
export class GetPreferredSegmentsResponse_Segment extends Message<GetPreferredSegmentsResponse_Segment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: double ratio = 4;
   */
  ratio = 0;

  constructor(data?: PartialMessage<GetPreferredSegmentsResponse_Segment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetPreferredSegmentsResponse.Segment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "ratio", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreferredSegmentsResponse_Segment {
    return new GetPreferredSegmentsResponse_Segment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreferredSegmentsResponse_Segment {
    return new GetPreferredSegmentsResponse_Segment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreferredSegmentsResponse_Segment {
    return new GetPreferredSegmentsResponse_Segment().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreferredSegmentsResponse_Segment | PlainMessage<GetPreferredSegmentsResponse_Segment> | undefined, b: GetPreferredSegmentsResponse_Segment | PlainMessage<GetPreferredSegmentsResponse_Segment> | undefined): boolean {
    return proto3.util.equals(GetPreferredSegmentsResponse_Segment, a, b);
  }
}

/**
 * GetRelevantProducts
 *
 * @generated from message product.GetRelevantProductsRequest
 */
export class GetRelevantProductsRequest extends Message<GetRelevantProductsRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRelevantProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetRelevantProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRelevantProductsRequest {
    return new GetRelevantProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRelevantProductsRequest {
    return new GetRelevantProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRelevantProductsRequest {
    return new GetRelevantProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRelevantProductsRequest | PlainMessage<GetRelevantProductsRequest> | undefined, b: GetRelevantProductsRequest | PlainMessage<GetRelevantProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetRelevantProductsRequest, a, b);
  }
}

/**
 * @generated from message product.GetRelevantProductsResponse
 */
export class GetRelevantProductsResponse extends Message<GetRelevantProductsResponse> {
  /**
   * @generated from field: repeated product.GetRelevantProductsResponse.Product products = 1;
   */
  products: GetRelevantProductsResponse_Product[] = [];

  constructor(data?: PartialMessage<GetRelevantProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetRelevantProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: GetRelevantProductsResponse_Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRelevantProductsResponse {
    return new GetRelevantProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRelevantProductsResponse {
    return new GetRelevantProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRelevantProductsResponse {
    return new GetRelevantProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRelevantProductsResponse | PlainMessage<GetRelevantProductsResponse> | undefined, b: GetRelevantProductsResponse | PlainMessage<GetRelevantProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetRelevantProductsResponse, a, b);
  }
}

/**
 * @generated from message product.GetRelevantProductsResponse.Product
 */
export class GetRelevantProductsResponse_Product extends Message<GetRelevantProductsResponse_Product> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount = protoInt64.zero;

  constructor(data?: PartialMessage<GetRelevantProductsResponse_Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "product.GetRelevantProductsResponse.Product";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRelevantProductsResponse_Product {
    return new GetRelevantProductsResponse_Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRelevantProductsResponse_Product {
    return new GetRelevantProductsResponse_Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRelevantProductsResponse_Product {
    return new GetRelevantProductsResponse_Product().fromJsonString(jsonString, options);
  }

  static equals(a: GetRelevantProductsResponse_Product | PlainMessage<GetRelevantProductsResponse_Product> | undefined, b: GetRelevantProductsResponse_Product | PlainMessage<GetRelevantProductsResponse_Product> | undefined): boolean {
    return proto3.util.equals(GetRelevantProductsResponse_Product, a, b);
  }
}

