import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { SkeletonTable } from 'components/skeletonTable'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'utils/timeUtil'
import { InviteMemberDialog } from './components/inviteMemberDialog'
import { RemoveMemberDialog } from './components/removeMemberDialog'
import { Account, AccountRole } from 'gen/firestore'

type Props = {
  account: Account
}

type RemoveMemberDialogState = {
  open: boolean
  email: string
}

type Member = {
  email: string
  role: string // AccountRole
  status: string // AccountStatus
  createdAt: string
}

export const Members: FC<Props> = ({ account }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const [members, setMembers] = useState<Member[]>()
  const [dialogSubmitted, setDialogSubmitted] = useState<number>(0)

  const [inviteMemberDialogOpen, setInviteMemberDialogOpen] = useState<boolean>(false)
  const [removeMemberDialogState, setRemoveMemberDialogState] = useState<RemoveMemberDialogState>({ open: false, email: '' })

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await shopService.getAccounts({}, { headers: { Authorization: `Bearer ${token}` } })
        const members = resp.accounts.map((a) => {
          return {
            email: a.email,
            role: a.role,
            status: a.status,
            createdAt: formatDateTime(dayjs(a.createdAt), i18n.language),
          }
        })
        setMembers(members)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogSubmitted])

  return (
    <>
      <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <Typography fontWeight='bold'>{t('features.settings.members.title')}</Typography>

          <Button size='small' variant='contained' disabled={account.role !== AccountRole.owner} onClick={() => setInviteMemberDialogOpen(true)}>
            {t('features.settings.members.invite')}
          </Button>
        </Box>

        {!members ? (
          <SkeletonTable rowCount={3} columnCount={4} />
        ) : (
          <TableContainer>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-head': {
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: '13px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                >
                  <TableCell>{t('features.settings.members.email')}</TableCell>
                  <TableCell>{t('features.settings.members.role')}</TableCell>
                  <TableCell>{t('features.settings.members.status')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((row) => (
                  <TableRow key={row.email}>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{t('features.settings.members.role', { context: row.role })}</TableCell>
                    <TableCell>{t('features.settings.members.status', { context: row.status })}</TableCell>
                    <TableCell align='right'>
                      {account.role === AccountRole.owner ? (
                        <Tooltip title={t('features.settings.members.remove')} placement='top'>
                          <span>
                            <IconButton
                              disabled={row.email === account.email}
                              onClick={(e) => {
                                setRemoveMemberDialogState({ open: true, email: row.email })
                              }}
                            >
                              <PersonRemoveOutlinedIcon fontSize='small' />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <IconButton disabled>
                          <PersonRemoveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      {inviteMemberDialogOpen && (
        <InviteMemberDialog
          open={inviteMemberDialogOpen}
          handleClose={() => setInviteMemberDialogOpen(false)}
          onSubmit={() => setDialogSubmitted((count) => count + 1)}
        />
      )}

      {removeMemberDialogState.open && (
        <RemoveMemberDialog
          open={removeMemberDialogState.open}
          handleClose={() => setRemoveMemberDialogState({ open: false, email: '' })}
          onSubmit={() => setDialogSubmitted((count) => count + 1)}
          email={removeMemberDialogState.email}
        />
      )}
    </>
  )
}
