import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Container, Link, TextField, Typography } from '@mui/material'
import { auth } from 'config/firebaseClient'
import { FirebaseError } from 'firebase/app'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

export const ResetPassword = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const loginInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.reset.messageValidationEmailIsRequired') })
      .email(t('features.reset.messageValidationEmailIsInvalid')),
  })

  type InputSchema = z.infer<typeof loginInputSchema>

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, submitCount, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(loginInputSchema),
  })

  const handleResetPassword: SubmitHandler<InputSchema> = async (input) => {
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/${PUBLIC_ROUTE.LOGIN}`,
      }
      auth.languageCode = i18n.language
      await sendPasswordResetEmail(auth, input.email, actionCodeSettings)
      enqueueSnackbar(t('features.reset.messageSuccess'), { severity: 'success' })
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/user-not-found') {
          enqueueSnackbar(t('features.reset.messageValidationEmailIsInvalid'), { severity: 'error' })
          return
        }
      }
      enqueueSnackbar(t('features.reset.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: input.email })
    }
  }

  return (
    <Container maxWidth='xl' fixed sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: '45%' } }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '8px' }}>{t('features.reset.title')}</Typography>
        <Typography sx={{ marginBottom: '32px' }}>{t('features.reset.description')}</Typography>
        <Box sx={{ marginBottom: '32px' }}>
          <TextField
            autoComplete='email'
            label={t('features.reset.email')}
            size='small'
            fullWidth
            autoFocus
            error={!!formErrors.email?.message}
            helperText={formErrors.email?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('email')}
          />
          <LoadingButton
            type='submit'
            size='medium'
            fullWidth
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(handleResetPassword)}
            disabled={submitCount > 0 && !isValid}
            variant='contained'
          >
            {t('features.reset.submit')}
          </LoadingButton>
        </Box>

        <Typography sx={{ fontSize: '14px' }}>
          <Link
            onClick={() => {
              navigate(PUBLIC_ROUTE.LOGIN)
            }}
            sx={{ fontWeight: 600, textDecoration: 'none', cursor: 'pointer', color: (theme) => theme.palette.text.secondary }}
          >
            {t('features.reset.backToLogin')}
          </Link>
        </Typography>
      </Box>

      <Box sx={{ width: '40%', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <img src={getImagePath('reset.png')} width='100%' alt='login' />
      </Box>
    </Container>
  )
}
