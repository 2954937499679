import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_ORDER_SEQUENCE_FIRST, OP_ORDER_SEQUENCE_LAST, OP_ORDER_SEQUENCE_SECOND, OP_ORDER_SEQUENCE_SPECIFY } from '../../querySetForm/schema/const'
import { FilterItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { OperatorChip } from '../operatorChip'
import { ValueChip } from '../valueChip'

type Props = {
  filterItem: FilterItemFormState
}

export const OrderSequenceFilter: FC<Props> = ({ filterItem }) => {
  const { t } = useTranslation()

  return (
    <>
      <OperatorChip label={t('features.customerSegments.querySet.filterType', { context: filterItem.filter_type })} />

      {filterItem.operator === OP_ORDER_SEQUENCE_FIRST && (
        <ValueChip label={t('features.customerSegments.querySet.querySetView.op_order_sequence', { context: OP_ORDER_SEQUENCE_FIRST })} />
      )}
      {filterItem.operator === OP_ORDER_SEQUENCE_SECOND && (
        <ValueChip label={t('features.customerSegments.querySet.querySetView.op_order_sequence', { context: OP_ORDER_SEQUENCE_SECOND })} />
      )}
      {filterItem.operator === OP_ORDER_SEQUENCE_LAST && (
        <ValueChip label={t('features.customerSegments.querySet.querySetView.op_order_sequence', { context: OP_ORDER_SEQUENCE_LAST })} />
      )}
      {filterItem.operator === OP_ORDER_SEQUENCE_SPECIFY && (
        <>
          {filterItem.value.min_sequence && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixSequence', { count: filterItem.value.min_sequence })} />
          )}
          {filterItem.value.min_sequence && filterItem.value.max_sequence && filterItem.value.min_sequence !== filterItem.value.max_sequence && (
            <OperatorChip label='~' />
          )}
          {filterItem.value.min_sequence && !filterItem.value.max_sequence && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orMore')} />
          )}
          {filterItem.value.max_sequence && filterItem.value.min_sequence !== filterItem.value.max_sequence && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixSequence', { count: filterItem.value.max_sequence })} />
          )}
          {!filterItem.value.min_sequence && filterItem.value.max_sequence && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orLess')} />
          )}
        </>
      )}
    </>
  )
}
