import { Code, ConnectError } from '@bufbuild/connect-web'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, FormControl, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  onSubmit: () => void
}

export const InviteMemberDialog: FC<Props> = ({ open, handleClose, onSubmit }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const shopService = useGrpcClient(ShopService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const Input = z.object({
    email: z
      .string()
      .min(1, { message: t('features.settings.members.inviteMemberDialog.messageValidationEmailMin') })
      .email(t('features.settings.members.inviteMemberDialog.messageValidationEmailIsInvalid')),
  })

  type InputSchema = z.infer<typeof Input>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(Input),
    defaultValues: {
      email: '',
    },
  })

  const inviteMember = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.inviteAccount({ email: input.email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.members.inviteMemberDialog.messageSaved'), { severity: 'success' })
      onSubmit()
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.settings.members.inviteMemberDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.settings.members.inviteMemberDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='4px'>
          {t('features.settings.members.inviteMemberDialog.title')}
        </Typography>
        <Typography fontSize='13px' color='text.secondary' marginBottom='16px'>
          {t('features.settings.members.inviteMemberDialog.description')}
        </Typography>

        <Box marginBottom='20px'>
          <Controller
            control={control}
            name='email'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.settings.members.inviteMemberDialog.fieldEmail')}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.settings.members.inviteMemberDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(inviteMember)} variant='contained'>
            {t('features.settings.members.inviteMemberDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
