import { Code, ConnectError } from '@bufbuild/connect-web'
import Picker from '@emoji-mart/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, FormControl, Popover, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
}

export const CreateDialog: FC<Props> = ({ open, handleClose }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const segmentGroupService = useGrpcClient(SegmentGroupService)

  const defaultEmojiList = ['😀', '🥰', '😎', '👋', '🐶', '💐', '🔥', '🥑', '🍔', '🏋️‍♀️', '🚗', '🚀', '⏰', '🎁', '🎉']
  const defaultEmoji = defaultEmojiList[Math.floor(Math.random() * defaultEmojiList.length)]

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(defaultEmoji)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const input = z.object({
    name: z
      .string()
      .min(1, { message: t('features.customerSegments.group.createDialog.messageValidationNameMin') })
      .max(64, { message: t('features.customerSegments.group.createDialog.messageValidationNameMax') }),
  })

  type InputSchema = z.infer<typeof input>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(input),
    defaultValues: {
      name: '',
    },
  })

  const createSegmentGroup = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      const resp = await segmentGroupService.create({ name: `${emojiIcon} ${input.name}` }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.group.createDialog.messageSaved'), { severity: 'success' })
      handleClose()
      navigate(generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: resp.segmentGroupId }))
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.customerSegments.group.createDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.customerSegments.group.createDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='16px'>
          {t('features.customerSegments.group.createDialog.title')}
        </Typography>

        <Box display='flex' alignItems='center' marginBottom='20px' gap='12px'>
          <Button
            ref={emojiAnchorRef}
            variant='outlined'
            onClick={() => setOpenEmojiPicker(true)}
            sx={{ borderColor: (theme) => theme.palette.action.selected, fontSize: '22px', padding: '0px' }}
          >
            {emojiIcon}
          </Button>
          <Popover
            open={openEmojiPicker}
            anchorEl={emojiAnchorRef.current}
            onClose={() => setOpenEmojiPicker(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Picker
              locale={i18n.language}
              onEmojiSelect={(emoji: any) => {
                setEmojiIcon(emoji.native)
                setOpenEmojiPicker(false)
              }}
            />
          </Popover>

          <Controller
            control={control}
            name='name'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '360px' }}>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.customerSegments.group.createDialog.fieldName')}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end' gap='12px'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='outlined'>
            {t('features.customerSegments.group.createDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(createSegmentGroup)} variant='contained'>
            {t('features.customerSegments.group.createDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
