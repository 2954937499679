import { Integration } from './type'

export const useIntegrations = (language: string): { data: Integration[] } => {
  const integrations: Integration[] = [
    { key: 'shopifyemail', languages: ['en', 'ja'] },
    { key: 'klaviyo', languages: ['en', 'ja'] },
    { key: 'mailchimp', languages: ['en', 'ja'] },
    { key: 'omnisend', languages: ['en', 'ja'] },
    { key: 'uppromote', languages: ['en', 'ja'] },
    { key: 'pushowl', languages: ['en', 'ja'] },
    { key: 'qikify', languages: ['en', 'ja'] },
    { key: 'aitrillion', languages: ['en', 'ja'] },
    { key: 'pagefly', languages: ['en', 'ja'] },
    { key: 'recurpay', languages: ['en'] },
    { key: 'bss', languages: ['en'] },
    { key: 'onemobile', languages: ['en'] },
    { key: 'privy', languages: ['en', 'ja'] },
    { key: 'asklayer', languages: ['en', 'ja'] },
    { key: 'channeltalk', languages: ['en', 'ja'] },
    { key: 'appify', languages: ['ja'] },
    { key: 'vip', languages: ['ja'] },
    { key: 'omnihub', languages: ['ja'] },
    { key: 'crmplusonline', languages: ['ja'] },
    { key: 'storecrm', languages: ['ja'] },
  ]

  const filteredIntegrations = integrations.filter((integration) => {
    return integration.languages.includes(language as 'en' | 'ja')
  })

  return { data: filteredIntegrations }
}
