// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/ai_assist/ai_assist.proto (package ai_assist, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * ProvideInsightForCustomerSegment
 *
 * @generated from message ai_assist.ProvideInsightForCustomerSegmentRequest
 */
export class ProvideInsightForCustomerSegmentRequest extends Message<ProvideInsightForCustomerSegmentRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  /**
   * @generated from field: string question = 2;
   */
  question = "";

  constructor(data?: PartialMessage<ProvideInsightForCustomerSegmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "ai_assist.ProvideInsightForCustomerSegmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProvideInsightForCustomerSegmentRequest {
    return new ProvideInsightForCustomerSegmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProvideInsightForCustomerSegmentRequest {
    return new ProvideInsightForCustomerSegmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProvideInsightForCustomerSegmentRequest {
    return new ProvideInsightForCustomerSegmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProvideInsightForCustomerSegmentRequest | PlainMessage<ProvideInsightForCustomerSegmentRequest> | undefined, b: ProvideInsightForCustomerSegmentRequest | PlainMessage<ProvideInsightForCustomerSegmentRequest> | undefined): boolean {
    return proto3.util.equals(ProvideInsightForCustomerSegmentRequest, a, b);
  }
}

/**
 * @generated from message ai_assist.ProvideInsightForCustomerSegmentResponse
 */
export class ProvideInsightForCustomerSegmentResponse extends Message<ProvideInsightForCustomerSegmentResponse> {
  /**
   * answer to the question referring to a relevant insight report
   *
   * @generated from field: string answer = 1;
   */
  answer = "";

  /**
   * type: InsightDimension
   *
   * @generated from field: string dimension = 2;
   */
  dimension = "";

  /**
   * type: InsightReportType
   *
   * @generated from field: string reportType = 3;
   */
  reportType = "";

  constructor(data?: PartialMessage<ProvideInsightForCustomerSegmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "ai_assist.ProvideInsightForCustomerSegmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "reportType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProvideInsightForCustomerSegmentResponse {
    return new ProvideInsightForCustomerSegmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProvideInsightForCustomerSegmentResponse {
    return new ProvideInsightForCustomerSegmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProvideInsightForCustomerSegmentResponse {
    return new ProvideInsightForCustomerSegmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProvideInsightForCustomerSegmentResponse | PlainMessage<ProvideInsightForCustomerSegmentResponse> | undefined, b: ProvideInsightForCustomerSegmentResponse | PlainMessage<ProvideInsightForCustomerSegmentResponse> | undefined): boolean {
    return proto3.util.equals(ProvideInsightForCustomerSegmentResponse, a, b);
  }
}

