import { Box, Button, Container, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'

export const FatalError: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container
      maxWidth='md'
      fixed
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 'calc(100vh - 128px)',
      }}
    >
      <Box sx={{ width: '50%', display: { xs: 'none', sm: 'none', md: 'block' }, marginBottom: '32px' }}>
        <img src={getImagePath('fatal_error.png')} width='100%' alt='fatalError' />
      </Box>

      <Typography fontSize='24px' fontWeight='bold' marginBottom='24px'>
        {t('features.error.fatalError.title')}
      </Typography>

      <Typography fontSize='14px' marginBottom='24px'>
        <Trans i18nKey='features.error.fatalError.description' />
      </Typography>

      <Button onClick={() => window.location.reload()} variant='contained'>
        {t('features.error.fatalError.submit')}
      </Button>
    </Container>
  )
}
