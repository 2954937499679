import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Collapse, IconButton, Paper, Skeleton, Tab, Tooltip, Typography } from '@mui/material'
import { GuideIcon } from 'components/guideIcon'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { SegmentMenu } from 'features/customerSegments/components/segmentMenu'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { Customers } from './components/customers'
import { Insights } from './components/insights'
import { Metrics } from './components/metrics'
import { Orders } from './components/orders'
import { SuggestInsightDialog } from './components/suggestInsightDialog'
import { Sync } from './components/sync'
import { useCustomerSegment } from './hooks/useCustomerSegment'
import { useCustomers } from './hooks/useCustomers'
import { useOrders } from './hooks/useOrders'
import { useTagsRunningStatus } from './hooks/useTagsRunningStatus'

enum TabIndex {
  metrics = 'metrics',
  insights = 'insights',
  orders = 'orders',
  customers = 'customers',
  sync = 'sync',
}

export const Detail: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const params = useParams()
  const customerSegmentId = params.id

  const customerSegment = useCustomerSegment(customerSegmentId)
  const { customers, loading: loadingCustomers } = useCustomers(customerSegment)
  const { orders, loading: loadingOrders } = useOrders(customerSegment)
  const { currentTagRunning, otherTagsRunning } = useTagsRunningStatus(customerSegmentId)

  const [menuOpen, setMenuOpen] = useState(false)
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [suggestInsightDialogOpen, setSuggestInsightDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const handleAskAiOpen = () => {
    if (shopBillingStatus === ShopBillingStatus.free) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    setSuggestInsightDialogOpen(true)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('askai') === 'true') {
      handleAskAiOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const initialTabIndex = (): TabIndex => {
    if (location.hash === `#${TabIndex.insights}`) return TabIndex.insights
    if (location.hash === `#${TabIndex.orders}`) return TabIndex.orders
    if (location.hash === `#${TabIndex.customers}`) return TabIndex.customers
    if (location.hash === `#${TabIndex.sync}`) return TabIndex.sync
    return TabIndex.metrics
  }
  const [tabIndex, setTabIndex] = useState<TabIndex>(initialTabIndex)

  return (
    <>
      <Box marginBottom='16px'>
        <Box
          display='flex'
          alignItems='center'
          marginBottom='4px'
          sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)}
          width='fit-content'
        >
          <ArrowBackIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
          <Typography fontSize='12px'>{t('features.customerSegments.detail.backToOverview')}</Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Typography variant='h2' fontSize='18px' fontWeight='500' sx={{ maxWidth: '700px' }}>
              {customerSegment?.name || <Skeleton width='120px' />}
            </Typography>

            <GuideIcon guideType='CustomerSegmentDetail' />

            <Tooltip
              title={collapseOpen ? t('features.customerSegments.detail.hideDetails') : t('features.customerSegments.detail.showDetails')}
              placement='top'
            >
              <span>
                <IconButton color='inherit' disabled={!customerSegment} onClick={() => setCollapseOpen(!collapseOpen)} sx={{ marginLeft: '2px' }}>
                  {collapseOpen ? <UnfoldLessIcon fontSize='small' /> : <UnfoldMoreIcon fontSize='small' />}
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={t('features.customerSegments.detail.menu')} placement='top'>
              <span>
                <IconButton color='inherit' disabled={!customerSegment} onClick={() => setMenuOpen(true)} ref={menuAnchorRef}>
                  <MoreHorizIcon />
                </IconButton>
              </span>
            </Tooltip>
            {customerSegment && (
              <SegmentMenu
                open={menuOpen}
                handleClose={() => setMenuOpen(false)}
                anchorEl={menuAnchorRef.current}
                customerSegment={customerSegment}
                page='detail'
              />
            )}
          </Box>

          <Box display='flex' gap='8px'>
            <Button variant='contained' size='small' startIcon={<AutoAwesomeOutlinedIcon />} onClick={handleAskAiOpen}>
              {t('features.customerSegments.detail.askAi')}
            </Button>
            <Button
              variant='outlined'
              size='small'
              startIcon={<EmojiObjectsOutlinedIcon />}
              onClick={() => {
                dispatch('ClickViewInsights', { customerSegment: customerSegment?.name })
                navigate(`${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${customerSegment?.id}`)
              }}
            >
              {t('features.customerSegments.detail.viewInsights')}
            </Button>
          </Box>
        </Box>

        <Collapse in={collapseOpen}>
          <Paper variant='outlined' sx={{ padding: '24px', marginTop: '16px' }}>
            {customerSegment && (
              <>
                <Box marginBottom='8px'>
                  <Typography fontSize='14px' marginTop='4px' color={(theme) => theme.palette.grey[600]}>
                    {customerSegment ? customerSegment.description : <Skeleton />}
                  </Typography>
                </Box>
                {customerSegment ? (
                  <QuerySet querySet={convertQuerySetToForm(convertStringToApiQuerySetState(customerSegment.querySet))} />
                ) : (
                  <Skeleton />
                )}
              </>
            )}
          </Paper>
        </Collapse>
      </Box>

      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(_, v) => setTabIndex(v)}>
            <Tab
              label={t('features.customerSegments.detail.tabIndexMetrics')}
              component={Link}
              to=''
              value={TabIndex.metrics}
              state={location.state ? { from: location.state.from } : null}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexInsights')}
              component={Link}
              to={`#${TabIndex.insights}`}
              value={TabIndex.insights}
              state={location.state ? { from: location.state.from } : null}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexOrders')}
              component={Link}
              to={`#${TabIndex.orders}`}
              value={TabIndex.orders}
              state={location.state ? { from: location.state.from } : null}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexCustomers')}
              component={Link}
              to={`#${TabIndex.customers}`}
              value={TabIndex.customers}
              state={location.state ? { from: location.state.from } : null}
            />
            <Tab
              label={t('features.customerSegments.detail.tabIndexSync')}
              component={Link}
              to={`#${TabIndex.sync}`}
              value={TabIndex.sync}
              state={location.state ? { from: location.state.from } : null}
            />
          </TabList>
        </Box>
        <TabPanel value={TabIndex.metrics} sx={{ padding: '24px 12px' }}>
          <Metrics customerSegment={customerSegment} />
        </TabPanel>
        <TabPanel value={TabIndex.insights} sx={{ padding: '24px 12px' }}>
          <Insights customerSegment={customerSegment} />
        </TabPanel>
        <TabPanel value={TabIndex.orders} sx={{ padding: '24px 12px' }}>
          <Orders customerSegment={customerSegment} orders={orders} loading={loadingOrders} />
        </TabPanel>
        <TabPanel value={TabIndex.customers} sx={{ padding: '24px 12px' }}>
          <Customers customerSegment={customerSegment} customers={customers} loading={loadingCustomers} />
        </TabPanel>
        <TabPanel value={TabIndex.sync} sx={{ padding: '24px 12px' }}>
          <Sync customerSegment={customerSegment} currentTagRunning={currentTagRunning} otherTagsRunning={otherTagsRunning} />
        </TabPanel>
      </TabContext>

      {customerSegment && (
        <SuggestInsightDialog
          open={suggestInsightDialogOpen}
          handleClose={() => setSuggestInsightDialogOpen(false)}
          segmentId={customerSegment.id}
          segmentName={customerSegment.name}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOverview'
        />
      )}
    </>
  )
}
