// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/ai_assist/ai_assist.proto (package ai_assist, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {ProvideInsightForCustomerSegmentRequest, ProvideInsightForCustomerSegmentResponse} from "./ai_assist_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service ai_assist.AIAssistService
 */
export const AIAssistService = {
  typeName: "ai_assist.AIAssistService",
  methods: {
    /**
     * @generated from rpc ai_assist.AIAssistService.ProvideInsightForCustomerSegment
     */
    provideInsightForCustomerSegment: {
      name: "ProvideInsightForCustomerSegment",
      I: ProvideInsightForCustomerSegmentRequest,
      O: ProvideInsightForCustomerSegmentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

