// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/product/product.proto (package product, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetPreferredSegmentsRequest, GetPreferredSegmentsResponse, GetRelevantProductsRequest, GetRelevantProductsResponse} from "./product_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service product.ProductService
 */
export const ProductService = {
  typeName: "product.ProductService",
  methods: {
    /**
     * @generated from rpc product.ProductService.GetPreferredSegments
     */
    getPreferredSegments: {
      name: "GetPreferredSegments",
      I: GetPreferredSegmentsRequest,
      O: GetPreferredSegmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc product.ProductService.GetRelevantProducts
     */
    getRelevantProducts: {
      name: "GetRelevantProducts",
      I: GetRelevantProductsRequest,
      O: GetRelevantProductsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

