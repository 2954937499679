import { Box, Card, CardActionArea, CardContent, Tooltip, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { InsightDimensionProfile } from '../types/insightDimensions'
import { ReportTypeIcon } from './reportTypeIcon'

type Props = {
  insight: InsightDimensionProfile
  customerSegmentId: string
}

export const CustomerInsightCard: FC<Props> = ({ insight, customerSegmentId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
      sx={{
        borderRadius: '12px',
        height: '100%',
        position: 'relative',
        '&:hover': {
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
        },
        '&:hover .showOnHover': {
          visibility: 'visible',
        },
      }}
    >
      <CardActionArea
        onClick={() => {
          const to =
            generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
              id: insight.name,
            }) + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
          navigate(to)
        }}
        sx={{ height: '100%', padding: '2px 6px' }}
      >
        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <Box display='flex' alignItems='center' gap='10px' marginBottom='4px'>
            <Typography fontSize='24px'>{extractIconFromTextWithIcon(t('features.insights.dimension', { context: insight.name }))}</Typography>
            <Typography fontSize='14px'>{extractTextFromTextWithIcon(t('features.insights.dimension', { context: insight.name }))}</Typography>
          </Box>
          <Box marginBottom='12px'>
            <Typography fontSize='13px' color='text.secondary' noWrap textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
              {t(`features.insights.questions.${insight.name}_${insight.reportTypes[0]}`)}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              fontSize='11px'
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
                padding: '2px 6px',
                borderRadius: '2px',
              }}
            >
              {t('features.insights.category', { context: insight.category })}
            </Typography>
            <Box display='flex'>
              {insight.reportTypes.map((type) => (
                <Tooltip key={type} title={t('features.insights.detail.reportType', { context: type })}>
                  <Box display='flex' alignItems='center'>
                    <ReportTypeIcon reportType={type} color='action' sx={{ fontSize: '16px', marginRight: '4px' }} />
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
