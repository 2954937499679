import { useState, useEffect } from 'react'
import { useSentryNotifier } from './useSentryNotifier'

export type LocalStorageKey =
  | 'SidebarOpenGroups'
  | 'SidebarOpenFavoritedSegments'
  | 'SidebarShowTutorial'
  | 'HomeQuickStartGuide'
  | 'SegmentOverviewViewType'
  | 'SegmentGroupIDSequence'
  | 'FavoriteSegmentIDSequence'
  | 'SegmentGroupViewType'
  | 'DisableSegmentGroupSnackbar'
  | 'DisableGroupCanvasViewAlert'

function useLocalStorage<T>(key: LocalStorageKey, initialValue: T): [T, (value: T | ((val: T) => T)) => void] {
  const { notifySentry } = useSentryNotifier()

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (err) {
      notifySentry(err)
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (err) {
      notifySentry(err)
    }
  }

  useEffect(() => {
    setValue(storedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, JSON.stringify(initialValue)])

  return [storedValue, setValue]
}

export default useLocalStorage
