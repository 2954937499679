import DataObjectIcon from '@mui/icons-material/DataObject'
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import { Box, Button, ToggleButton, Tooltip } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus, customerSegmentCountLimit } from 'config/plan'
import dayjs from 'dayjs'
import { DimensionOptionsProvider } from 'features/customerSegments/context/dimensionOptionsContext'
import { defaultQuerySet } from 'features/customerSegments/querySetForm/defalutValues'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { useQuerySetForm } from 'features/customerSegments/querySetForm/useQuerySetForm'
import { query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CreateDialog } from './components/createDialog'
import { PreviewMetricsDialog } from './components/previewMetricsDialog'
import { PreviewQuerySetDialog } from './components/previewQuerySetDialog'

export const Create = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const querySetParam = queryParams.get('querySet')
  const defaultQuerySetFromParam = querySetParam && convertQuerySetToForm(convertStringToApiQuerySetState(decodeURIComponent(querySetParam)))

  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))

  const { QuerySetForm, querySetFormMethods } = useQuerySetForm({
    mode: 'onChange',
    defaultValues: defaultQuerySetFromParam || defaultQuerySet,
  })

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [previewMetricsDialogOpen, setPreviewMetricsDialogOpen] = useState(false)
  const [previewQuerySetDialogOpen, setPreviewQuerySetDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const handleMetricsDialogOpen = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) setPreviewMetricsDialogOpen(true)
  }

  const handleQuerySetDialogOpen = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) setPreviewQuerySetDialogOpen(true)
  }

  const handleCreateDialogOpen = async () => {
    if (!customerSegments) return
    if (shopBillingStatus === ShopBillingStatus.free && customerSegments.length >= customerSegmentCountLimit(shopBillingStatus)) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    const ok = await querySetFormMethods.trigger()
    if (ok) setCreateDialogOpen(true)
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        paddingBottom='24px'
        position='sticky'
        top='80px'
        zIndex='1'
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <PageHeader title={t('features.customerSegments.create.title')} marginBottom='0px' guideType='CustomerSegmentCreate' />
        <Box display='flex'>
          <Tooltip title={t('features.customerSegments.create.previewQuerySet')} placement='top'>
            <ToggleButton size='small' value='check' onClick={handleQuerySetDialogOpen} sx={{ borderRadius: '6px', marginRight: '8px' }}>
              <DataObjectIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>
          <Tooltip title={t('features.customerSegments.create.previewMetrics')} placement='top'>
            <ToggleButton size='small' value='check' onClick={handleMetricsDialogOpen} sx={{ borderRadius: '6px', marginRight: '12px' }}>
              <ManageSearchOutlinedIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>
          <Button variant='contained' size='small' startIcon={<PushPinOutlinedIcon />} disabled={!customerSegments} onClick={handleCreateDialogOpen}>
            {t('features.customerSegments.create.submit')}
          </Button>
        </Box>
      </Box>

      <DimensionOptionsProvider>
        <QuerySetForm />
      </DimensionOptionsProvider>

      {previewMetricsDialogOpen && (
        <PreviewMetricsDialog
          open={previewMetricsDialogOpen}
          querySetFormMethods={querySetFormMethods}
          handleClose={() => setPreviewMetricsDialogOpen(false)}
        />
      )}

      {previewQuerySetDialogOpen && (
        <PreviewQuerySetDialog
          open={previewQuerySetDialogOpen}
          querySetFormMethods={querySetFormMethods}
          handleClose={() => setPreviewQuerySetDialogOpen(false)}
        />
      )}

      {createDialogOpen && (
        <CreateDialog open={createDialogOpen} querySetFormMethods={querySetFormMethods} handleClose={() => setCreateDialogOpen(false)} />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentCreate'
        />
      )}
    </>
  )
}
