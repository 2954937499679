import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Box,
  IconButton,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { OrderState } from 'features/customerSegments/types/types'
import { CustomerDrawer } from 'features/customers/customerDrawer'
import { OrderDrawer } from 'features/orders/orderDrawer'
import { useCurrency } from 'hooks/useCurrency'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSort } from 'hooks/useSort'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  loading: boolean
  orders: OrderState[] | undefined
}

type OrderDrawerState = {
  open: boolean
  order:
    | {
        id: string
        name: string
      }
    | undefined
}

type CustomerDrawerState = {
  open: boolean
  customer:
    | {
        id: string
        name: string
      }
    | undefined
}

export const OrdersTable: FC<Props> = ({ loading, orders }) => {
  const { t } = useTranslation()
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [page, setPage] = useState(0)
  const rowsPerPage = 10

  const [orderDrawerState, setOrderDrawerState] = useState<OrderDrawerState>({ open: false, order: undefined })
  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>({ open: false, customer: undefined })

  const { sortedData: sortedOrders, sort, toggleSort } = useSort<OrderState>(orders || [])

  return (
    <>
      {loading || !orders ? (
        <SkeletonTable rowCount={5} columnCount={6} />
      ) : orders.length === 0 ? (
        <EmptyState title={t('features.customerSegments.detail.orders.empty')} />
      ) : (
        <>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-head': {
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: '13px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                >
                  <TableCell>{t('features.customerSegments.detail.orders.name')}</TableCell>
                  <TableCell>{t('features.customerSegments.detail.orders.customerName')}</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.target === 'nthOrder'}
                      direction={sort.target === 'nthOrder' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                      onClick={() => toggleSort('nthOrder')}
                    >
                      {t('features.customerSegments.detail.orders.nthOrder')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.target === 'orderAmount'}
                      direction={sort.target === 'orderAmount' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                      onClick={() => toggleSort('orderAmount')}
                    >
                      {t('features.customerSegments.detail.orders.orderAmount')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.target === 'orderedAt'}
                      direction={sort.target === 'orderedAt' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                      onClick={() => toggleSort('orderedAt')}
                    >
                      {t('features.customerSegments.detail.orders.orderedAt')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOrders &&
                  sortedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography
                          variant='body2'
                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                          onClick={() => {
                            setOrderDrawerState({ open: true, order: { id: row.id, name: row.name } })
                            dispatch('OpenOrderDrawer', { orderId: row.id, orderName: row.name, referrer: 'customerSegment_orders' })
                          }}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant='body2'
                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                          onClick={() => {
                            setCustomerDrawerState({ open: true, customer: { id: row.customerId, name: row.customerName } })
                            dispatch('OpenCustomerDrawer', {
                              customerId: row.customerId,
                              customerName: row.customerName,
                              referrer: 'customerSegment_orders',
                            })
                          }}
                        >
                          {row.customerName}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.nthOrder}</TableCell>
                      <TableCell>{formatCurrency(row.orderAmount) || <Skeleton />}</TableCell>
                      <TableCell>{row.orderedAt}</TableCell>
                      <TableCell align='right'>
                        <Tooltip title={t('features.customerSegments.detail.orders.viewOrder')} placement='top'>
                          <span>
                            <IconButton
                              onClick={(e) => {
                                setOrderDrawerState({ open: true, order: { id: row.id, name: row.name } })
                                dispatch('OpenOrderDrawer', { orderId: row.id, orderName: row.name, referrer: 'customerSegment_orders' })
                              }}
                            >
                              <KeyboardArrowRightIcon fontSize='small' />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display='flex' justifyContent='flex-end'>
            <Pagination
              shape='rounded'
              count={orders ? Math.ceil(orders.length / rowsPerPage) : 0}
              page={page + 1}
              onChange={(_, v) => setPage(v - 1)}
              sx={{ marginTop: '20px' }}
            />
          </Box>
        </>
      )}

      <OrderDrawer
        open={orderDrawerState.open}
        handleOpen={() => setOrderDrawerState({ open: true, order: orderDrawerState.order })}
        handleClose={() => setOrderDrawerState({ open: false, order: undefined })}
        orderProps={orderDrawerState.order}
      />

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState({ open: false, customer: undefined })}
        customer={customerDrawerState.customer}
      />
    </>
  )
}
