import { Box, Button, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { getImagePath } from 'utils/imageUtil'

type Props = {
  title: string
  buttonText?: string
  buttonOnClick?: () => void
}

export const EmptyState: FC<Props> = ({ title, buttonText, buttonOnClick }) => {
  const theme = useTheme()

  return (
    <Box textAlign='center' padding='48px'>
      <Box
        component='img'
        src={theme.palette.mode === 'dark' ? getImagePath('empty_dark.svg') : getImagePath('empty.svg')}
        alt='Empty State'
        width='96px'
      />
      <Typography fontSize='15px' marginTop='16px' color='text.secondary'>
        {title}
      </Typography>
      {buttonText && buttonOnClick && (
        <Button variant='contained' size='small' sx={{ marginTop: '16px' }} onClick={buttonOnClick}>
          {buttonText}
        </Button>
      )}
    </Box>
  )
}
