import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined'
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { SegmentGroup } from 'gen/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDateTime, formatYearMonth } from 'utils/timeUtil'
import { CustomerSegmentState } from '../types/types'
import { DeleteDialog } from './components/deleteDialog'
import { UpdateDialog } from './components/updateDialog'

type Props = {
  open: boolean
  handleClose: () => void
  anchorEl: HTMLElement | null
  customerSegment: CustomerSegmentState
  page: 'overview' | 'detail'
  group?: SegmentGroup
}

export const SegmentMenu: FC<Props> = ({ open, handleClose, anchorEl, customerSegment, page, group }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const segmentGroupService = useGrpcClient(SegmentGroupService)

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const toggleFavorited = async (customerSegment: CustomerSegmentState) => {
    try {
      const token = await getIdToken(authUser!)
      const newFavoritedState = !customerSegment.favorited
      await customerSegmentService.update(
        {
          customerSegmentId: customerSegment.id,
          name: customerSegment.name,
          description: customerSegment.description,
          favorited: newFavoritedState,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (newFavoritedState) {
        dispatch('AddToFavorites', { id: customerSegment.id, name: customerSegment.name })
        enqueueSnackbar(t('features.customerSegments.components.segmentMenu.messageAddToFavorites'), { severity: 'success' })
      } else {
        dispatch('RemoveFromFavorites', { id: customerSegment.id, name: customerSegment.name })
        enqueueSnackbar(t('features.customerSegments.components.segmentMenu.messageRemoveFromFavorites'), { severity: 'success' })
      }
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.components.segmentMenu.error'), { severity: 'error' })
      notifySentry(err)
    }
  }

  const removeSegmentFromGroup = async () => {
    if (!group) return
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.removeSegments(
        {
          segmentGroupId: group.ref.id,
          customerSegmentIds: [customerSegment.id],
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.components.segmentMenu.messageRemoveFromGroupSuccess'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.components.segmentMenu.messageRemoveFromGroupError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList dense sx={{ padding: '0px' }}>
          {page === 'overview' && (
            <MenuItem
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, {
                  id: customerSegment.id,
                })
                window.open(to, '_blank')
                handleClose()
              }}
            >
              <ListItemIcon>
                <OpenInNewIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('features.customerSegments.components.segmentMenu.open')}</ListItemText>
            </MenuItem>
          )}

          {page === 'overview' && <Divider />}

          {page === 'overview' && (
            <MenuItem
              onClick={() => {
                handleClose()
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, {
                  id: customerSegment.id,
                })
                navigate(`${to}?askai=true`)
              }}
            >
              <ListItemIcon>
                <AutoAwesomeOutlinedIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('features.customerSegments.components.segmentMenu.askAi')}</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              handleClose()
              toggleFavorited(customerSegment)
            }}
          >
            {!customerSegment.favorited ? (
              <>
                <ListItemIcon>
                  <StarBorderIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('features.customerSegments.components.segmentMenu.addToFavorites')}</ListItemText>
              </>
            ) : (
              <>
                <ListItemIcon>
                  <StarIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('features.customerSegments.components.segmentMenu.removeFromFavorites')}</ListItemText>
              </>
            )}
          </MenuItem>

          {page === 'overview' && (
            <MenuItem
              onClick={() => {
                handleClose()
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, {
                  id: customerSegment.id,
                })
                navigate(`${to}#sync`)
              }}
            >
              <ListItemIcon>
                <LocalOfferOutlinedIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('features.customerSegments.components.segmentMenu.assignCustomerTag')}</ListItemText>
            </MenuItem>
          )}

          {group && (
            <MenuItem
              onClick={() => {
                removeSegmentFromGroup()
                handleClose()
              }}
            >
              <ListItemIcon>
                <FolderOffOutlinedIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('features.customerSegments.components.segmentMenu.removeFromGroup')}</ListItemText>
            </MenuItem>
          )}

          <Divider />

          {page === 'overview' && (
            <MenuItem
              onClick={() => {
                handleClose()
                const to = `${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${customerSegment?.id}`
                navigate(to)
              }}
            >
              <ListItemIcon>
                <EmojiObjectsOutlinedIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('features.customerSegments.components.segmentMenu.viewInsights')}</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              handleClose()
              const to = `${AUTHORIZED_ROUTE.COHORTS_MONTHLY}?start_year_month=${formatYearMonth(
                dayjs().subtract(1, 'year')
              )}&end_year_month=${formatYearMonth(dayjs())}&customer_segment_id=${customerSegment.id}`
              navigate(to)
            }}
          >
            <ListItemIcon>
              <CalendarMonthOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('features.customerSegments.components.segmentMenu.viewMonthlyCohort')}</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              handleClose()
              navigate({
                pathname: AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE,
                search: createSearchParams({
                  querySet: encodeURIComponent(customerSegment.querySet),
                }).toString(),
              })
            }}
          >
            <ListItemIcon>
              <ContentCopyOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('features.customerSegments.components.segmentMenu.duplicate')}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose()
              setUpdateDialogOpen(true)
            }}
          >
            <ListItemIcon>
              <EditOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('features.customerSegments.components.segmentMenu.edit')}</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose()
              setDeleteDialogOpen(true)
            }}
          >
            <ListItemIcon>
              <DeleteOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('features.customerSegments.components.segmentMenu.delete')}</ListItemText>
          </MenuItem>

          <Divider />

          <Box padding='4px 20px'>
            <Typography fontSize='12px' color='text.secondary'>
              {t('features.customerSegments.components.segmentMenu.createdAt')}
            </Typography>
            <Typography fontSize='12px' color='text.secondary'>
              {formatDateTime(dayjs(customerSegment.createdAt), i18n.language)}
            </Typography>
          </Box>
        </MenuList>
      </Menu>

      {updateDialogOpen && (
        <UpdateDialog
          open={updateDialogOpen}
          handleClose={() => setUpdateDialogOpen(false)}
          id={customerSegment.id}
          name={customerSegment.name}
          description={customerSegment.description}
          favorited={customerSegment.favorited}
        />
      )}

      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          id={customerSegment.id}
          name={customerSegment.name}
          tagOperationSettingIsActive={customerSegment.tagOperationSetting?.isActive || false}
        />
      )}
    </>
  )
}
