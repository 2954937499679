import dayjs from 'dayjs'
import { BillingPlanIdentifier, Shop, ShopBillingStatus } from 'gen/firestore'
import { useTranslation } from 'react-i18next'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'

type PlanConfig = {
  [key in BillingPlanIdentifier]: {
    price?: number
    minActiveCustomerCount: number
    maxActiveCustomerCount: number
  }
}

export const planConfig: PlanConfig = {
  free: {
    price: 0,
    minActiveCustomerCount: 0,
    maxActiveCustomerCount: Infinity,
  },
  starter: {
    price: 80,
    minActiveCustomerCount: 0,
    maxActiveCustomerCount: 5000,
  },
  growth: {
    price: 240,
    minActiveCustomerCount: 5001,
    maxActiveCustomerCount: 20000,
  },
  scale: {
    price: 400,
    minActiveCustomerCount: 20001,
    maxActiveCustomerCount: 100000,
  },
  enterprise: {
    minActiveCustomerCount: 100001,
    maxActiveCustomerCount: Infinity,
  },
}

export const billingStatus = (shop: Shop, currentTime: dayjs.Dayjs): ShopBillingStatus => {
  if (shop.billingPlan.identifier !== BillingPlanIdentifier.free || shop.isDemo) {
    return ShopBillingStatus.paid
  } else if (dayjs(shop.trialEndsAt.toDate()).isAfter(currentTime)) {
    return ShopBillingStatus.trial
  } else {
    return ShopBillingStatus.free
  }
}

const SEGMENT_COUNT_LIMIT_FREE = 5
const SEGMENT_COUNT_LIMIT_UNLIMITED = 999

export const customerSegmentCountLimit = (shopBillingStatus: ShopBillingStatus): number => {
  if (shopBillingStatus === ShopBillingStatus.free) {
    return SEGMENT_COUNT_LIMIT_FREE
  } else {
    return SEGMENT_COUNT_LIMIT_UNLIMITED
  }
}

export const isPlanSelectable = (shop: Shop, plan: BillingPlanIdentifier): boolean => {
  // Unselectable if the shop is demo
  if (shop.isDemo) return false

  // Unselectable if the plan is already selected
  if (plan === shop.billingPlan.identifier) return false
  // Selectable if the plan is free
  if (plan === BillingPlanIdentifier.free) return true

  // Selectable if the active customer count is within the range
  const min = planConfig[plan].minActiveCustomerCount
  const max = planConfig[plan].maxActiveCustomerCount
  return min <= shop.activeCustomerCount && shop.activeCustomerCount <= max
}

export const isUpgradeRequired = (shop: Shop): boolean => {
  return shop.billingPlan.maxActiveCustomerCount < shop.activeCustomerCount
}

export const useUpgradeFeatureList = () => {
  const { t } = useTranslation()
  return ['segment', 'insight', 'download', 'advanced'].map((feature) => ({
    icon: extractIconFromTextWithIcon(t(`common.upgradeFeatures.${feature}_title`)),
    title: extractTextFromTextWithIcon(t(`common.upgradeFeatures.${feature}_title`)),
    description: t(`common.upgradeFeatures.${feature}_desc`),
  }))
}
