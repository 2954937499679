import { Box, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { EmptyState } from 'components/emptyState'
import dayjs from 'dayjs'
import { OrderState } from 'features/customerSegments/types/types'
import { useCurrency } from 'hooks/useCurrency'
import { FC } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'

type chartData = {
  x: string
  y: number
}

type Props = {
  loading: boolean
  orders: OrderState[] | undefined
  startDate: string // YYYY-MM-DD
  endDate: string // YYYY-MM-DD
}

export const OrdersGraph: FC<Props> = ({ loading, orders, startDate, endDate }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { formatCurrency } = useCurrency()

  const aggregateOrders = (orders: OrderState[], lang: string): chartData[] => {
    const aggregation: Record<string, number> = {}
    orders.forEach((o) => {
      const date = formatDate(dayjs(o.orderedAt), lang)
      if (!aggregation[date]) {
        aggregation[date] = 0
      }
      aggregation[date] += o.orderAmount
    })

    // Fill in missing dates with 0
    const result: chartData[] = []
    let date = dayjs(startDate)
    while (date.isBefore(dayjs(endDate).add(1, 'day'))) {
      const dateString = formatDate(date, lang)
      result.push({ x: dateString, y: aggregation[dateString] || 0 })
      date = date.add(1, 'day')
    }

    result.sort((a, b) => dayjs(a.x).diff(dayjs(b.x)))

    return result
  }

  return loading || !orders ? (
    <Box display='flex' justifyContent='center' alignItems='center' width='100%' height={400}>
      <CircularProgress />
    </Box>
  ) : orders.length === 0 ? (
    <EmptyState title={t('features.customerSegments.detail.orders.empty')} />
  ) : (
    <Chart
      type='line'
      height={400}
      series={(() => {
        const chartData = aggregateOrders(orders, i18n.language)
        return [
          {
            name: t('features.customerSegments.detail.orders.totalOrderAmount'),
            data: chartData,
          },
        ]
      })()}
      options={{
        xaxis: {
          labels: {
            style: {
              colors: theme.palette.text.primary,
            },
          },
          tickAmount: 30,
        },
        yaxis: {
          labels: {
            style: {
              colors: theme.palette.text.primary,
            },
            formatter: (value) => {
              return value.toLocaleString()
            },
          },
        },
        tooltip: {
          theme: theme.palette.mode,
          y: {
            formatter: (y) => formatCurrency(y) || y.toLocaleString(),
          },
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        legend: {
          labels: {
            colors: theme.palette.text.primary,
          },
        },
        chart: {
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        colors: [theme.palette.primary.main],
      }}
    />
  )
}
