import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Grid, IconButton, LinearProgress, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { PageHeader } from 'components/pageHeader'
import { SkeletonTable } from 'components/skeletonTable'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { OP_CONTAIN_ANY, OP_NOT_CONTAIN } from 'features/customerSegments/querySetForm/schema/const'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { ProductService } from 'gen/proto/product/product_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { FreePlanSkeletonText } from './component/freePlanSkeletonText'

const DEFAULT_VISIBLE_COUNT = 3

type PreferredSegment = {
  id: string
  name: string
  customerCount: number
  ratio: number
  isVisible: boolean
}

type RelevantProduct = {
  name: string
  customerCount: number
  isVisible: boolean
}

export const ProductInsights = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const productService = useGrpcClient(ProductService)
  const { dispatch } = useActionTracker()
  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useCollection(query(dimensionOptionsRef))

  const products = useMemo(() => {
    if (!data) return undefined
    const productDoc = data.find((doc) => doc.dimension === 'product')
    return productDoc ? productDoc.options : []
  }, [data])

  const [loading, setLoading] = useState(false)
  const [targetProduct, setTargetProduct] = useState<string>()
  const [preferredSegments, setPreferredSegments] = useState<PreferredSegment[]>()
  const [relevantProducts, setRelevantProducts] = useState<RelevantProduct[]>()

  const generateQuerySet = (targetProduct: string, relevantProduct: string): QuerySetFormState => {
    return {
      join_operator: 'and',
      query_subsets: [
        {
          join_operator: 'and',
          query_items: [
            { dimension: 'product', operator: OP_CONTAIN_ANY, value: [relevantProduct], filters: [] },
            { dimension: 'product', operator: OP_NOT_CONTAIN, value: [targetProduct], filters: [] },
          ],
        },
      ],
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    dispatch('SearchProductInsight', { product: targetProduct })

    try {
      const token = await getIdToken(authUser!)

      // Fetch preferred segments and relevant products in parallel
      const fetchPreferredSegments = productService.getPreferredSegments({ name: targetProduct }, { headers: { Authorization: `Bearer ${token}` } })
      const fetchRelevantProducts = productService.getRelevantProducts({ name: targetProduct }, { headers: { Authorization: `Bearer ${token}` } })
      const [preferredSegmentsResponse, relevantProductsResponse] = await Promise.all([fetchPreferredSegments, fetchRelevantProducts])

      // Format preferred segments
      const formatPreferredSegments = preferredSegmentsResponse.segments.map((segment, i) => ({
        id: segment.id,
        name: segment.name,
        customerCount: Number(segment.customerCount),
        ratio: Number(segment.ratio.toFixed(1)),
        isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
      }))
      setPreferredSegments(formatPreferredSegments)

      // Format relevant products
      const formatRelevantProducts = relevantProductsResponse.products.map((product, i) => ({
        name: product.name,
        customerCount: Number(product.customerCount),
        isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
      }))
      setRelevantProducts(formatRelevantProducts)
    } catch (err) {
      enqueueSnackbar(t('features.productInsights.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageHeader title={t('features.productInsights.title')} marginBottom='24px' />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center' gap='16px'>
            <Autocomplete
              disableClearable
              options={products || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} variant='outlined' placeholder={t('features.productInsights.selectProduct')} />}
              sx={{ width: '100%', cursor: 'pointer', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
              onChange={(_, value) => setTargetProduct(value || '')}
            />
            <LoadingButton
              variant='contained'
              color='primary'
              disabled={!targetProduct}
              onClick={handleSubmit}
              loading={loading}
              sx={{ width: '160px', borderRadius: '8px' }}
            >
              {t('features.productInsights.submit')}
            </LoadingButton>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px' }}>
            <Box marginBottom='24px'>
              <Box display='flex' alignItems='center' marginBottom='2px'>
                <GroupRoundedIcon fontSize='small' sx={{ marginRight: '8px', fontSize: '18px', color: 'text.secondary' }} />
                <Typography>{t('features.productInsights.preferredSegmentsTitle')}</Typography>
              </Box>
              <Typography fontSize='13px' color='text.secondary'>
                {t('features.productInsights.preferredSegmentsDesc')}
              </Typography>
            </Box>
            {loading ? (
              <SkeletonTable rowCount={5} columnCount={3} />
            ) : !preferredSegments ? (
              <EmptyState title={t('features.productInsights.preferredSegmentsUndefined')} />
            ) : preferredSegments.length === 0 ? (
              <EmptyState title={t('features.productInsights.preferredSegmentsEmpty')} />
            ) : (
              <Grid container spacing={2.5} sx={{ paddingX: '8px' }}>
                {preferredSegments.map((segment) => (
                  <Grid key={segment.id} item xs={12}>
                    <Box>
                      <Box display='flex' alignItems='center' marginBottom='8px'>
                        <Typography fontSize='14px'>{segment.isVisible ? segment.name : <FreePlanSkeletonText />}</Typography>
                        {segment.isVisible && (
                          <Tooltip title={t('features.productInsights.openInNew')} placement='top'>
                            <IconButton
                              size='small'
                              onClick={() => {
                                dispatch('ClickPreferredSegment', { product: targetProduct, segment: segment.name })
                                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: segment.id })
                                window.open(to, '_blank')
                              }}
                              sx={{ padding: 0, marginLeft: '8px' }}
                            >
                              <OpenInNewIcon sx={{ fontSize: '14px' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <LinearProgress
                          variant='determinate'
                          value={segment.ratio * 100}
                          sx={{
                            flexGrow: 1,
                            marginRight: '12px',
                            borderRadius: '4px',
                            height: '8px',
                          }}
                        />
                        <Tooltip title={segment.customerCount.toLocaleString()} placement='top'>
                          <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                            <LocalFireDepartmentRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px' }} />
                            {segment.ratio * 100}%
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper variant='outlined' sx={{ borderRadius: '8px', padding: '28px 24px' }}>
            <Box marginBottom='24px'>
              <Box display='flex' alignItems='center' marginBottom='2px'>
                <Inventory2RoundedIcon fontSize='small' sx={{ marginRight: '8px', fontSize: '18px', color: 'text.secondary' }} />
                <Typography>{t('features.productInsights.relevantProductsTitle')}</Typography>
              </Box>
              <Typography fontSize='13px' color='text.secondary'>
                {t('features.productInsights.relevantProductsDesc')}
              </Typography>
            </Box>
            {loading ? (
              <SkeletonTable rowCount={5} columnCount={3} />
            ) : !relevantProducts ? (
              <EmptyState title={t('features.productInsights.relevantProductsUndefined')} />
            ) : relevantProducts.length === 0 ? (
              <EmptyState title={t('features.productInsights.relevantProductsEmpty')} />
            ) : (
              <Grid container spacing={2.5} sx={{ paddingX: '8px' }}>
                {relevantProducts.map((product) => (
                  <Grid key={product.name} item xs={12}>
                    <Box>
                      <Box display='flex' alignItems='center' marginBottom='8px'>
                        <Typography fontSize='14px'>{product.isVisible ? product.name : <FreePlanSkeletonText />}</Typography>
                        {targetProduct && product.isVisible && (
                          <Tooltip title={t('features.productInsights.createSegment')} placement='top'>
                            <IconButton
                              size='small'
                              onClick={() => {
                                dispatch('ClickRelevantProduct', { targetProduct: targetProduct, relevantProduct: product.name })
                                const querySet = generateQuerySet(targetProduct, product.name)
                                const querySetJsonString = JSON.stringify(convertFormToQuerySet(querySet as QuerySetFormState))
                                window.open(
                                  `${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?${createSearchParams({
                                    querySet: encodeURIComponent(querySetJsonString),
                                  }).toString()}`,
                                  '_blank'
                                )
                              }}
                              sx={{ padding: 0, marginLeft: '8px' }}
                            >
                              <DriveFileRenameOutlineRoundedIcon sx={{ fontSize: '14px' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <LinearProgress
                          variant='determinate'
                          value={(product.customerCount / relevantProducts[0].customerCount) * 100}
                          sx={{
                            flexGrow: 1,
                            marginRight: '12px',
                            borderRadius: '4px',
                            height: '8px',
                          }}
                        />
                        <Typography fontSize='13px' color='text.secondary' display='flex' alignItems='center'>
                          <PersonRoundedIcon fontSize='small' sx={{ fontSize: '16px', marginRight: '2px' }} />
                          {product.customerCount.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
