import { Box, Dialog } from '@mui/material'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  referrer: string // page name
}

export const InteractiveGuideDialog: FC<Props> = ({ open, handleClose, referrer }) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()

  useEffect(() => {
    dispatch('OpenInteractiveGuideDialog', { referrer })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box sx={{ height: '480px' }}>
        <iframe
          width='100%'
          height='480px'
          src={t('common.interactiveGuideDialog.iframeURL')}
          title='ECPower Tutorial Video'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{ border: 'none', borderRadius: '8px' }}
        />
      </Box>
    </Dialog>
  )
}
