import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useUpgradeFeatureList } from 'config/plan'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { GuideIcon } from './guideIcon'

type Props = {
  open: boolean
  handleClose: () => void
  referrer: string // page name
}

// UpgradeRecommendDialog is used when users on the free plan exceed the maximum limit of resources they have created.
export const UpgradeRecommendDialog: FC<Props> = ({ open, handleClose, referrer }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const upgradeFeatureList = useUpgradeFeatureList()

  useEffect(() => {
    dispatch('OpenUpgradeRecommendDialog', { referrer })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px'>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '12px', right: '12px' }}>
          <CloseIcon fontSize='small' />
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize='18px' fontWeight='bold' marginBottom='8px'>
              {t('common.upgradeRecommendDialog.title')}
            </Typography>
            <Typography color='text.secondary' fontSize='14px'>
              {t('common.upgradeRecommendDialog.description')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{
                padding: '16px',
                borderRadius: '8px',
                backgroundColor: (theme) => theme.palette.primary.main + '1A',
                borderColor: (theme) => theme.palette.primary.main + '1A',
              }}
            >
              <Box display='flex' alignItems='center' gap='2px' marginBottom='16px'>
                <Typography fontWeight='bold'>{t('common.upgradeRecommendDialog.upgradePlanFeatureTitle')}</Typography>
                <GuideIcon guideType='UpgradeFeature' />
              </Box>
              <Grid container spacing={2}>
                {upgradeFeatureList.map((feature, index) => (
                  <Grid item xs={12} key={index}>
                    <Box display='flex' gap='12px'>
                      <Typography fontSize='24px'>{feature.icon}</Typography>
                      <Box>
                        <Typography fontSize='14px'>{feature.title}</Typography>
                        <Typography fontSize='12px' color='text.secondary'>
                          {feature.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    if (window.ChannelIO) {
                      window.ChannelIO('show') // Open ChannelTalk
                    }
                  }}
                  variant='outlined'
                  fullWidth
                >
                  {t('common.upgradeRecommendDialog.talk')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    dispatch('ClickCheckPlan', { referrer })
                    navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
                  }}
                  variant='contained'
                  fullWidth
                >
                  {t('common.upgradeRecommendDialog.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
